// import { Link } from 'react-router-dom'
// import SectionCard from '../SectionCard'
import { TextInput, Tooltip } from 'flowbite-react'
import { useState } from 'react'
import { FaBasketballBall, FaCompass } from 'react-icons/fa'
import { GiGuitar, GiMicrophone, GiWineBottle } from 'react-icons/gi'
import { HiFilm, HiSearch } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { useApiContext } from '../../utilis/ApiContext'
import { CategoryModalContext } from '../../utilis/Context'
import { SearchEvent } from '../EventCard'

export const icons = [
   {
      id: 1,
      name: 'Concert',
      HiCon: GiGuitar,
      icon: 'person',
   },
   {
      id: 4,
      name: 'Comedy',
      icon: 'person',
      HiCon: GiMicrophone,
   },
   {
      id: 2,
      name: 'Sports',
      HiCon: FaBasketballBall,
   },
   {
      id: 3,
      name: 'Movie',
      icon: 'movie',
      HiCon: HiFilm,
   },
   {
      id: 5,
      name: 'Party',
      HiCon: GiWineBottle,
   },
   {
      id: 6,
      name: 'Découvrir',
      HiCon: FaCompass,
   },
]

export default function SearchBar({ categories = true }) {
   // // const ref = useRef(null)
   // const [scrollDirection, setScrollDirection] = useState('none')

   // useEffect(() => {
   //    let lastScrollPosition = 0

   //    const handleScroll = () => {
   //       const currentScrollPosition = window.scrollY
   //       const isScrollingUp = currentScrollPosition < lastScrollPosition
   //       setScrollDirection(isScrollingUp ? 'up' : 'down')
   //       lastScrollPosition = currentScrollPosition
   //    }

   //    window.addEventListener('scroll', handleScroll)

   //    return () => {
   //       window.removeEventListener('scroll', handleScroll)
   //    }
   // }, [])

   // useEffect(() => {
   //    console.log(scrollDirection)
   // }, [scrollDirection])
   return (
      <div
         className={
            'items- flex w-full flex-row justify-center gap-5 px-5 md:h-20  md:p-5'
            // +
            // (scrollDirection === 'up' ? 'sticky top-0' : '')
         }
      >
         <Search className="lg:w-6/12" />
         {categories && (
            <div className="hidden flex-row justify-center gap-5 lg:flex">
               {icons.map(({ name, icon, id, HiCon }) => (
                  <Category key={id} name={name} icon={icon} HiCon={HiCon} />
               ))}
            </div>
         )}
      </div>
   )
}

export function Search({ className }) {
   const { events } = useApiContext()
   const [search, setSearch] = useState([])

   const handdleSearch = (e) => {
      const word = e.target.value

      word.length > 3
         ? setSearch(
              events.filter(({ title }) => {
                 return title.toLowerCase().includes(word.toLowerCase())
              }),
           )
         : setSearch([])
   }

   return (
      <div
         className={
            'flex w-full flex-col gap-2 pt-0 sm:pt-4 md:pt-0 ' + className
         }
      >
         <TextInput
            className="w-full rounded-full"
            id="search"
            type="search"
            icon={HiSearch}
            // autoFocus
            onChange={(e) => handdleSearch(e)}
            // rightIcon={HiMail}
            placeholder="Trouver un Événement"
            required
         />
         {search && search.length > 0 && (
            <div className=" overflow-y- z-10 flex flex-row flex-wrap gap-2 rounded-lg bg-white p-2 dark:bg-dark-400  md:p-4">
               <div className=" grid w-full grid-cols-1 gap-2 md:grid-cols-2   ">
                  {search.map((event) => (
                     <>
                        <SearchEvent
                           event={event}
                           key={event?.id + event?.contact + 'search'}
                        />
                     </>
                  ))}
                  {search.length < 0 && <span>No results</span>}
               </div>
            </div>
         )}
      </div>
   )
}
export function ExploreSearch({ className }) {
   const { events } = useApiContext()
   const [search, setSearch] = useState([])

   const handdleSearch = (e) => {
      const word = e.target.value

      word.length > 3
         ? setSearch(
              events.filter(({ title }) => {
                 return title.toLowerCase().includes(word.toLowerCase())
              }),
           )
         : setSearch([])
   }

   return (
      <div className={'flex w-full flex-col gap-2 md-max:py-2 ' + className}>
         <TextInput
            className="w-full rounded-full"
            id="search"
            type="search"
            icon={HiSearch}
            autoFocus
            onChange={(e) => handdleSearch(e)}
            // rightIcon={HiMail}
            placeholder="Chercher un Evénement"
            required
         />
         {search && search.length > 0 && (
            <div className=" z-10 flex flex-row flex-wrap gap-2 rounded-lg bg-white p-2 dark:bg-dark-400  md:p-4">
               <div className="grid w-full grid-cols-1 gap-2 md:grid-cols-2  ">
                  {search.map((event) => (
                     <>
                        <SearchEvent
                           event={event}
                           key={event?.id + event?.contact + 'search'}
                        />
                     </>
                  ))}
                  {search.length < 0 && <span>No results</span>}
               </div>
            </div>
         )}
      </div>
   )
}

function Category({ name, icon, HiCon }) {
   // const openModal = useExpoloreModalContext()
   const navigate = useNavigate()
   return (
      <CategoryModalContext.Consumer>
         {({ changeCategory }) => (
            <Tooltip
               className="text-3"
               content={name}
               placement="bottom"
               style="light"
            >
               <div
                  onClick={() => {
                     name.toLocaleLowerCase() === 'découvrir'
                        ? navigate('/explore')
                        : changeCategory(name)
                  }}
                  className="flex cursor-pointer rounded-full border  bg-white p-3 hover:bg-primary-500 hover:text-white dark:border-gray-600 dark:bg-dark-400 dark:text-slate-200"
               >
                  {HiCon ? (
                     <HiCon className="text-6" />
                  ) : (
                     <span className="material-icons-round">{icon}</span>
                  )}
               </div>
            </Tooltip>
         )}
      </CategoryModalContext.Consumer>
   )
}

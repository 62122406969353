import { HiChevronLeft } from 'react-icons/hi'

export default function CustomPopUp({
   children,
   state,
   close,
   title,
   rightBtn,
}) {
   return (
      state === true && (
         <div className="fixed left-0 top-0 z-100 flex h-[100dvh] w-screen items-center justify-center bg-black bg-opacity-40 p-4">
            <div className="flex max-h-[90dvh] w-100 flex-col overflow-y-scroll rounded-xl bg-darktext-white dark:bg-dark-400 md:w-120">
               <div className="flex h-10 w-full items-center justify-between rounded-t-xl border-b px-4 py-6 dark:border-none dark:bg-dark ">
                  <HiChevronLeft
                     onClick={() => close()}
                     className="material-icons-round cursor-pointer justify-end rounded p-1 text-8 text-dark hover:bg-darktext dark:text-darktext dark:hover:bg-dark-400"
                  />
                  <span className="font-semibold text-dark dark:text-darktext-white">
                     {title}
                  </span>
                  {rightBtn ? rightBtn : <div></div>}
               </div>
               {children}
            </div>
         </div>
      )
   )
}

// import { styled } from 'styled-components'
import { Modal } from 'flowbite-react'
import { HiX } from 'react-icons/hi'
import { useApiContext } from '../../utilis/ApiContext'
import { useCategoryModalContext } from '../../utilis/Context'
import EventCard from '../EventCard'
export default function Categories() {
   const { events } = useApiContext()
   const { modalState, closeModal, category } = useCategoryModalContext()
   return (
      <Modal
         size="4xl"
         show={modalState}
         onClose={closeModal}
         popup
         className="h-[100svh] overflow-hidden"
         // dismissible={true}
      >
         <div className="flex w-full items-center justify-center px-4 pb-2 pt-4">
            <div className="flex w-full items-center justify-center">
               <span className="text-5 font-bold capitalize text-gray-600 dark:text-slate-200">
                  {category ? category : 'Search By Category'}
               </span>
            </div>
            <div
               onClick={() => closeModal()}
               className="flex cursor-pointer flex-row items-center gap-2 rounded-full px-2 py-1 font-bold text-gray-DEFAULT-600 dark:text-slate-200 dark:hover:bg-gray-600"
            >
               <HiX className="text-5" />
            </div>
         </div>
         <Modal.Body className="h-full overflow-y-auto rounded-xl dark:bg-dark-400 sm-max:p-0">
            <div className="flex h-full w-full flex-col flex-wrap items-center p-2 dark:bg-inherit sm:rounded-xl md:min-h-120">
               <div className="flex w-full flex-wrap gap-1 p-2">
                  <span className="font-semibold text-gray-600 dark:text-slate-400">
                     Résultats pour la Catégorie :{' '}
                  </span>
                  <span className="font-bold capitalize text-primary dark:text-darktext-white">
                     {category}
                  </span>
               </div>
               <div className="mt-2 grid h-full w-full grid-cols-1 grid-rows-1 items-center gap-4 rounded-xl dark:bg-inherit md:min-h-90 md:grid-cols-2">
                  {events &&
                     category &&
                     events
                        .filter((evnt) => {
                           if (category === 'tous les événements') {
                              return evnt
                           } else {
                              if (category === 'Événements Populaires') {
                                 return Number(evnt?.is_sponsored) === 1
                              } else {
                                 return (
                                    evnt.category.name.toLocaleLowerCase() ===
                                    category.toLocaleLowerCase()
                                 )
                              }
                           }
                        })
                        .map((event) => (
                           <EventCard event={event} key={event.id + 'cats'} />
                        ))}
               </div>
            </div>
            {/* <Action /> */}
         </Modal.Body>
      </Modal>
   )
}

// function TicketCategory({ type, price, currency }) {
//    return (
//       <div className="rounded-xl w-full p-4 flex flex-row justify-between gap-2 items-center bg-bg">
//          <div className="flex flex-row gap-2 items-center">
//             <HiOutlineTicket className=" -rotate-45 text-8 text-blue-500" />
//             <span className="font-semibold">{type}</span>
//             <span className="font-semibold">{price + currency}</span>
//          </div>
//          <span className="material-icons-round justify-end">info</span>
//       </div>
//    )
// }

// function Action() {
//    return (
//       <div className="w-full absolute p-4 bottom-0 left-0 flex justify-center items-center">
//          <div className="flex gap-2 items-center py-3 px-4 rounded-full bg-primary">
//             <HiOutlineTicket className=" -rotate-45 text-6 text-white " />
//             <span className="font-semibol text-4 text-white">Book Ticket</span>
//          </div>
//       </div>
//    )
// }

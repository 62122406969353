import { LuExternalLink } from 'react-icons/lu'
export default function Footer() {
   return (
      <div className="m4 mb-19 flex w-full max-w-screen-xl flex-col-reverse items-center justify-between p-4 text-center text-3 text-gray-600 dark:text-slate-400  sm:rounded-xl md:mb-5 md:text-4">
         <div className="flex flex-col items-center">
            <span className="font-light">from</span>
            <a
               target="_blank"
               className="flex items-center gap-2 text-5 font-semibold hover:text-primary-500 dark:text-slate-200"
               href="http://kwetutech.com"
               rel="noreferrer"
            >
               <span>KwetuTech</span> <LuExternalLink />
            </a>
         </div>
      </div>
   )
}

import { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import img from '../../assets/imgs/bg.png'

import { Modal, useThemeMode } from 'flowbite-react'
import { FaCalendar, FaEye, FaRegClock } from 'react-icons/fa'
import {
   HiChevronLeft,
   HiLocationMarker,
   HiMap,
   HiOutlineTicket,
   HiX,
} from 'react-icons/hi'
import bg from '../../assets/imgs/bg.png'
import bg_dark from '../../assets/imgs/bg_dark.png'
import { apiLink, appUrl, eventAssetsLink } from '../../utilis/ApiContext'
import { getLoggedUser, useEventModalContext } from '../../utilis/Context'
import { usePost } from '../../utilis/CustomHooks'
import { getDate } from '../../utilis/functions'
import Booking from '../Booking'
import Share from '../Share'

const Banner = styled.div`
   background-image: url('${(props) => props.bg}');
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
   width: 100%;
`

export default function EventDetails() {
   const [booking, setBooking] = useState(false)
   const { mode } = useThemeMode()
   const closeBooking = () => {
      setBooking(false)
   }
   const openBooking = () => {
      setBooking(true)
   }
   const { modalState, closeModal, event } = useEventModalContext()
   // use

   return (
      <Modal
         className="overflow-hidden sm-max:p-0"
         size="3xl"
         show={modalState}
         // onClose={}
         popup
         // position={'top-left'}
         // dismissible
         // className
      >
         {/* <Modal.Header className="p-3 md:p-4 border-b dark:border-none">
                  <span className="font-semibold pl-6 mt-2 text-center">
                     {event ? event?.title : 'Event Title'}
                  </span>
               </Modal.Header> */}
         <Modal.Body className="max-h-[100dvh] overflow-y-auto rounded-xl p-4 dark:bg-dark-400 sm-max:p-0">
            <div className="flex h-full w-full flex-col items-center bg-white dark:bg-dark-400 sm:rounded-xl">
               <div className="flex w-full flex-col items-center dark:text-darktext-white">
                  <Banner bg={mode === 'light' ? bg : bg_dark}>
                     <div
                        style={{
                           backgroundImage:
                              'url(' +
                              (event ? eventAssetsLink + event?.banner : img) +
                              ')',
                        }}
                        className="flex h-80 w-full justify-center overflow-hidden rounded-xl  border bg-cover bg-center backdrop-blur-sm dark:border-none md:h-80"
                     >
                        <Banner
                           bg={event ? eventAssetsLink + event?.banner : img}
                           className="flex items-start p-4 backdrop-blur-md"
                           // alt="article_image"
                           // srcSet=""
                        >
                           <div className="flex h-full w-full flex-col    justify-between gap-2 rounded-xl text-dark dark:text-darktext-white">
                              <div className="flex w-full items-center justify-between">
                                 {booking ? (
                                    <div
                                       onClick={() => closeBooking()}
                                       className="flex cursor-pointer flex-row items-center gap-2 rounded-full bg-white px-2 py-1 font-bold text-gray-DEFAULT-600"
                                    >
                                       <HiChevronLeft className="text-5" />{' '}
                                       Retour
                                    </div>
                                 ) : (
                                    <span className="cursor-pointer rounded-full border border-white  bg-black bg-opacity-40 px-2 py-1 text-4 font-bold capitalize text-darktext-white  dark:border-none dark:text-slate-200">
                                       {event
                                          ? event?.category?.name
                                          : 'concert'}
                                    </span>
                                 )}

                                 <div
                                    onClick={() => {
                                       setBooking()
                                       closeModal()
                                    }}
                                    className="flex cursor-pointer flex-row items-center gap-2 rounded-full bg-white p-2 font-bold text-gray-DEFAULT-600"
                                 >
                                    <HiX className="text-5" />
                                 </div>
                              </div>

                              <div className="flex flex-row items-end justify-between">
                                 <span className="flex items-center justify-center gap-1 rounded-full bg-white px-2 py-1 dark:bg-dark-400 sm-max:text-3">
                                    <FaEye className="text-4" /> {event?.views}{' '}
                                    Vues
                                 </span>
                                 <span className="flex items-center justify-center gap-1 rounded-full bg-white px-2 py-1 dark:bg-dark-400 sm-max:text-3">
                                    <Like event={event} />
                                 </span>
                              </div>
                           </div>
                        </Banner>
                     </div>
                  </Banner>
                  {booking ? (
                     <Booking
                        event={event}
                        show={booking}
                        onClose={closeBooking}
                     />
                  ) : (
                     <>
                        <div className="flex w-full flex-col gap-2 p-2 sm:p-4">
                           <div className="flex w-full flex-row items-center justify-between gap-1 px-2 pb-2 md:pb-4">
                              <div className="flex flex-col">
                                 <span className="w-full font-bold dark:text-darktext-white md:text-5">
                                    {event
                                       ? event?.title && event?.title
                                       : 'Event name'}
                                 </span>
                                 <span className="w-full italic dark:text-darktext-white md-max:text-3">
                                    Par{' '}
                                    {event &&
                                       event?.organizer &&
                                       event?.organizer}
                                 </span>
                              </div>
                              <Share
                                 title={event?.title}
                                 text={event?.title + 'sur KwetuEvent \n'}
                                 link={appUrl + '/events/' + event?.dynamic_id}
                              />
                           </div>
                           <div className="mb-16 overflow-y-auto pb-2">
                              <div className="flex w-full flex-col gap-4 p-2">
                                 <div className="flex flex-row items-center justify-between gap-4">
                                    <div className="flex flex-row items-center gap-4">
                                       <span className="rounded-lg bg-sky-DEFAULT-200 from-indigo-600 to-blue-700 p-4 text-sky-DEFAULT-600 opacity-60 dark:bg-gradient-to-r">
                                          <FaRegClock className="text-sky-DEFAULT-600 dark:text-darktext-white" />
                                       </span>
                                       <span className="flex flex-col">
                                          <span className="capitalize text-gray-400 sm-max:text-3">
                                             {getDate(event?.date, 'long')}
                                          </span>
                                          <span className="font-semibold text-gray-800 dark:text-slate-200 sm:text-5">
                                             {event?.start_time} -{' '}
                                             {event?.end_time}
                                          </span>
                                       </span>
                                    </div>
                                    <span className="rounded-lg bg-sky-DEFAULT-200 from-indigo-600 to-blue-700 p-4 opacity-60 dark:bg-gradient-to-r">
                                       <FaCalendar className="text-sky-DEFAULT-600 dark:text-darktext-white" />
                                    </span>
                                 </div>
                                 <div className="flex flex-row items-center justify-between gap-4">
                                    <div className="flex flex-row items-center gap-4">
                                       <span className="rounded-lg bg-sky-DEFAULT-200 from-indigo-600 to-blue-700 p-4 opacity-60 dark:bg-gradient-to-r">
                                          <HiLocationMarker className="text-sky-DEFAULT-600 dark:text-darktext-white" />
                                       </span>
                                       <span className="flex flex-col">
                                          <span className="capitalize text-gray-400 sm-max:text-3">
                                             Venue :
                                          </span>
                                          <span className="font-semibold text-gray-800 dark:text-slate-200 sm:text-5">
                                             {event?.venue}
                                          </span>
                                       </span>
                                    </div>
                                    <span className="rounded-lg bg-sky-DEFAULT-200 from-indigo-600 to-blue-700 p-4 opacity-60 dark:bg-gradient-to-r">
                                       <HiMap className="text-sky-DEFAULT-600 dark:text-darktext-white" />
                                    </span>
                                 </div>
                              </div>

                              <div className="w-full px-2">
                                 <span className="font-bold dark:text-slate-200">
                                    Description :
                                 </span>
                                 <p className="text-slate-500 dark:text-slate-400 sm-max:text-3">
                                    {event
                                       ? event?.description
                                       : event?.category?.name}
                                 </p>
                              </div>
                           </div>
                           {/* <Support contacts={event?.contacts} /> */}
                        </div>
                        {event &&
                           !event?.onInvitation &&
                           event?.ticket_categories.length > 0 && (
                              <Action handler={openBooking} />
                           )}
                     </>
                  )}
               </div>
            </div>
         </Modal.Body>
      </Modal>
   )
}

// function TicketCategory({ type, price, currency, free }) {
//    return (
//       <div className="rounded-xl w-full p-4 flex flex-row justify-between gap-2 items-center dark:bg-dark bg-bg">
//          <div className="flex flex-row gap-2 items-center">
//             <HiOutlineTicket className=" -rotate-45 text-8 text-blue-500" />
//             <span className="font-semibold">{type}</span>
//             {free ? (
//                <span className="font-semibold">Free Entrance</span>
//             ) : (
//                <span className="font-semibold">{price + currency}</span>
//             )}
//          </div>
//          <span className="material-icons-round justify-end">info</span>
//       </div>
//    )
// }

function Action({ handler }) {
   return (
      <div className="absolute bottom-0 left-0 flex w-full items-center justify-center p-4 pb-4 sm-max:animate-bounce">
         <div
            onClick={() => handler()}
            className=" flex  cursor-pointer items-center gap-2 rounded-full bg-gradient-to-r from-indigo-600 to-blue-700 px-4 py-3"
         >
            <HiOutlineTicket className=" -rotate-45 text-6 text-white " />
            <span className="text-4 font-bold text-white">
               Acheter des Tickets
            </span>
         </div>
      </div>
   )
}
export function Like({ event }) {
   const oldLikes = Number(event?.likes)
   const [response, error, isLoading, setPost] = usePost(
      apiLink + '/api/events/like',
   )
   const [likes, setLikes] = useState(Number(event?.likes))
   const click = (e) => {
      // e.target.classList.add('absolute')
      getLoggedUser() &&
         setPost({
            user_id: getLoggedUser().user.id,
            event_id: event?.id,
         })
      e.target.classList.add('absolute')
      e.target.classList.add('text-10')
      e.target.classList.add('animate-ping')
      likes < oldLikes + 1 && setLikes(likes + 1)
      setTimeout(() => {
         // e.target.classList.remove('absolute')
         e.target.classList.remove('absolute')
         e.target.classList.remove('text-10')
         e.target.classList.remove('animate-ping')
      }, 1000)
   }

   useEffect(() => {}, [response, error, isLoading])

   return (
      <div className="flex flex-row items-center gap-1">
         <span
            onClick={(e) => click(e)}
            className="material-icons-round cursor-pointer text-red-700 dark:text-red-700"
         >
            favorite
         </span>
         <span className="dark:text-darktext-white">{likes} Interessés</span>
      </div>
   )
}

import { Link } from 'react-router-dom'
import { useNavContext } from '../../utilis/Context'
// import { useState } from "react";
export default function Nav({ title, link, icon, HiCon, onClick, rotate }) {
   const { activeNav, handler } = useNavContext()

   return (
      <Link to={link && link}>
         <div
            active={activeNav === title && true}
            onClick={() => {
               link && handler(title)
               onClick && onClick()
            }}
            className={
               activeNav === title
                  ? ' flex cursor-pointer flex-row items-center justify-center gap-2 text-4 font-semibold  text-primary dark:text-white dark:hover:text-white'
                  : 'flex cursor-pointer flex-row items-center justify-center gap-2 text-4 font-semibold text-gray-600 hover:text-primary dark:text-gray-400 dark:hover:text-white'
            }
         >
            {HiCon ? (
               <HiCon className={rotate ? ' -rotate-45 text-6 ' : 'text-6'} />
            ) : (
               <span className="material-icons-round text-5">{icon}</span>
            )}
            <span className="">{title}</span>
         </div>
      </Link>
   )
}

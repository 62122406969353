import { useThemeMode } from 'flowbite-react'
import { FaEye, FaHeart } from 'react-icons/fa'
import { HiLocationMarker } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import bg from '../../assets/imgs/bg.png'
import bg_dark from '../../assets/imgs/bg_dark.png'
import { eventAssetsLink } from '../../utilis/ApiContext'
import { useEventModalContext } from '../../utilis/Context'
import { getDate } from '../../utilis/functions'
const Banner = styled.div`
   background-image: url(${(props) => props.bg});
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
`
export default function EventCard({ event }) {
   const { changeEvent } = useEventModalContext()
   const { mode } = useThemeMode()
   return (
      <div
         // to={to.toString()}
         onClick={() => changeEvent(event)}
         className="justify- flex min-h-42 w-full min-w-60 cursor-pointer flex-col overflow-hidden rounded-xl border p-2 dark:border-none dark:bg-dark dark:hover:shadow-xs "
      >
         <Banner
            bg={mode === 'light' ? bg : bg_dark}
            className="h-52 w-full rounded-xl"
         >
            <Banner
               bg={event.banner && eventAssetsLink + event.banner}
               className="h-52 w-full rounded-xl"
            >
               {/* <div className="flex gap-1 items-center">
               <FaCalendar />
               <span className="sm:text-4">
                  {date
                     ? new Date(date).toLocaleDateString('fr')
                     : 'Samedi, 23 Decembre 2023'}
               </span>
            </div> */}
               <div className="flex h-full w-full flex-col    justify-between gap-2 rounded-xl text-dark dark:text-darktext-white">
                  <div className="flex items-center justify-between p-4">
                     <span className="s-max:text-3 inline rounded-full border border-darktext-white bg-black bg-opacity-25 px-2 py-1 font-semibold text-darktext-white dark:border-slate-400 dark:bg-dark dark:bg-opacity-60 dark:text-slate-200">
                        {event.category.name}
                     </span>
                     {/* <span className="flex items-center justify-center p-2 rounded-full bg-black bg-opacity-25 dark:bg-opacity-25 border border-white dark:bg-dark-400">
                     <FaRegBookmark className="text-6 text-white" />
                     <Like event={event} />
                  </span> */}
                  </div>
                  <div className="flex items-center justify-between p-4">
                     <span className="flex items-center justify-center gap-1 rounded-full bg-white px-2 dark:bg-dark-400">
                        <FaEye className="text-4" /> {event.views}
                     </span>
                     <span className="flex items-center justify-center gap-1 rounded-full bg-white px-2 dark:bg-dark-400">
                        <FaHeart className="text-4 text-pink-600" />{' '}
                        {event.likes}
                     </span>
                  </div>
               </div>
            </Banner>
         </Banner>
         <Description
            title={event.title}
            date={event.date}
            time={event.startTime}
            likes={event.likes}
            views={event.views}
            venue={event.venue}
         />
      </div>
   )
}
export function SearchEvent({ event }) {
   const { changeEvent } = useEventModalContext()
   return (
      <Link
         // to={to.toString()}
         onClick={() => changeEvent(event)}
         className="flex w-full flex-row justify-center  overflow-hidden rounded-xl border bg-bg dark:border-none dark:bg-dark dark:hover:bg-dark-400 dark:hover:shadow-xs md:h-auto"
      >
         <Banner
            bg={eventAssetsLink + event.banner}
            className="flex min-h-42 w-full flex-col justify-end rounded-xl p-2 "
         >
            <DescriptionSearch
               title={event.title}
               date={event.date}
               time={event.start_time}
               // likes={event.likes}
               // views={event.views}
               venue={event.venue}
            />
         </Banner>
      </Link>
   )
}

function Description({ title, date, time, likes, views, venue }) {
   return (
      <div className="h-26 flex  w-full flex-row items-center justify-between rounded-xl dark:text-darktext">
         <div className="flex h-full flex-col gap-1 p-4">
            {/* <span className="h-1/2 max-w-full overflow-hidden text-ellipsis font-bold text-gray-800 dark:text-darktext-white">
               {title
                  ? title.slice(0, 20) + (title?.length > 24 ? ' ...' : '')
                  : 'Title'}
            </span> */}
            <span className="h-1/2 max-w-full overflow-hidden text-ellipsis font-bold text-gray-800 dark:text-darktext-white">
               {title ? title : 'Title'}
            </span>
            <div className="flex h-1/2 justify-between text-gray-600">
               <div className="flex items-center gap-1">
                  <HiLocationMarker className="dark:text-slate-200" />
                  <span className=" dark:text-slate-200 sm-max:text-3">
                     {venue}
                  </span>
               </div>
            </div>
         </div>
         <div className="flex flex-col items-center justify-center rounded-xl bg-gradient-to-r from-indigo-600 to-blue-700 px-4 py-1">
            <span className="text-6 font-bold text-darktext-white">
               {getDate(date).split(' ')[1]}
            </span>
            <span className="capitalize text-slate-200">
               {getDate(date).split(' ')[2]}
            </span>
         </div>
      </div>
   )
}

function DescriptionSearch({ title, date, venue }) {
   return (
      <div className="flex max-h-32 w-full flex-row items-center justify-between rounded-xl bg-white p-2 dark:bg-dark dark:text-darktext">
         <div className="flex h-full flex-col gap-1 p-2">
            <span className="h-1/2  overflow-hidden text-ellipsis text-3 font-bold text-gray-800 dark:text-darktext-white md:text-4">
               {title ? title : 'Event Title'}
            </span>
            <div className="flex h-1/2 justify-between text-gray-600">
               <div className="flex items-center gap-1">
                  <HiLocationMarker className="dark:text-slate-200" />
                  <span className=" text-3 dark:text-slate-200">
                     {venue ? venue : 'Event Venue'}
                  </span>
               </div>
            </div>
         </div>
         <div className="flex flex-col items-center justify-center rounded-xl bg-gradient-to-r from-indigo-600 to-blue-700 p-2 py-1">
            <span className="text-5 font-bold text-darktext-white">
               {getDate(date).split(' ')[1]}
            </span>
            <span className="capitalize text-slate-200">
               {getDate(date).split(' ')[2]}
            </span>
         </div>
      </div>
   )
}

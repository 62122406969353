// import { styled } from 'styled-components'
import { Modal } from 'flowbite-react'
import { HiChevronLeft } from 'react-icons/hi'
import { ApiContext } from '../../utilis/ApiContext'
import { ExploreModalContext } from '../../utilis/Context'
import { getDate } from '../../utilis/functions'
import EventCard from '../EventCard'

export default function Dates() {
   return (
      <ApiContext.Consumer>
         {({ events }) => (
            <ExploreModalContext.Consumer>
               {({ dateModalState, closeDateModal, date }) => (
                  <Modal
                     size="3xl"
                     show={dateModalState}
                     onClose={closeDateModal}
                     popup
                     dismissible
                  >
                     <div className="flex w-full items-center justify-center px-4 pb-2 pt-4">
                        <div
                           onClick={() => closeDateModal()}
                           className="hoverbg-gray-200 flex cursor-pointer flex-row items-center gap-2 rounded-full px-2 py-1 font-bold text-gray-DEFAULT-600 dark:text-slate-200 dark:hover:bg-gray-600"
                        >
                           <HiChevronLeft className="text-5" />
                        </div>
                        <div className="flex w-full items-center justify-center">
                           <span className="font-bold capitalize text-gray-600 dark:text-slate-200 md:text-5">
                              {date
                                 ? 'Événements du ' + getDate(date)
                                 : 'Search By Date'}
                           </span>
                        </div>
                     </div>

                     <Modal.Body className="rounded-xl dark:bg-dark-400 sm-max:p-0">
                        <div className="mt-2 flex h-full min-h-120 w-full flex-col flex-wrap items-center bg-white px-2 dark:bg-dark-400 sm:rounded-xl">
                           <div className="flex w-full flex-row flex-wrap  gap-1 px-2">
                              <span className="font-semibold text-gray-600 dark:text-slate-400">
                                 Résultats pour:{' '}
                              </span>
                              <span className="font-bold capitalize text-primary dark:text-darktext-white">
                                 {getDate(date, 'long')}
                              </span>
                           </div>
                           <div className="mt-2 flex h-full min-h-90 w-full flex-row flex-wrap items-center gap-2 rounded-xl bg-white dark:bg-inherit">
                              {events &&
                                 date &&
                                 events
                                    .filter((evnt) => {
                                       return (
                                          new Date(
                                             evnt.date,
                                          ).toLocaleDateString() === date
                                       )
                                    })
                                    .map((event) => (
                                       <EventCard
                                          event={event}
                                          key={event.id + 'cats'}
                                       />
                                    ))}
                           </div>
                        </div>
                        {/* <Action /> */}
                     </Modal.Body>
                  </Modal>
               )}
            </ExploreModalContext.Consumer>
         )}
      </ApiContext.Consumer>
   )
}

// function TicketCategory({ type, price, currency }) {
//    return (
//       <div className="rounded-xl w-full p-4 flex flex-row justify-between gap-2 items-center bg-bg">
//          <div className="flex flex-row gap-2 items-center">
//             <HiOutlineTicket className=" -rotate-45 text-8 text-blue-500" />
//             <span className="font-semibold">{type}</span>
//             <span className="font-semibold">{price + currency}</span>
//          </div>
//          <span className="material-icons-round justify-end">info</span>
//       </div>
//    )
// }

// function Action() {
//    return (
//       <div className="w-full absolute p-4 bottom-0 left-0 flex justify-center items-center">
//          <div className="flex gap-2 items-center py-3 px-4 rounded-full bg-primary">
//             <HiOutlineTicket className=" -rotate-45 text-6 text-white " />
//             <span className="font-semibold text-4 text-white">Book Ticket</span>
//          </div>
//       </div>
//    )
// }

import { Button, TextInput } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
   HiChevronDown,
   HiLockClosed,
   HiMail,
   HiPencil,
   HiPhone,
   HiUser,
} from 'react-icons/hi'
import { apiLink } from '../../utilis/ApiContext'
import { usePopUpContext, useSessionContext } from '../../utilis/Context'
import { usePost } from '../../utilis/CustomHooks'
import { UserAvatar } from '../NavBar'

export default function Account() {
   const [identity, setIdentity] = useState(true)
   const [password, setPassword] = useState(false)
   const [userId, setUserId] = useState()
   const [thePassword, setThePassword] = useState(false)
   const [confirmPassword, setConfirmPassword] = useState(false)
   const [editIdentity, setEditIdentity] = useState(false)
   const { openPopUp, closePopUp } = usePopUpContext()
   const { startSession } = useSessionContext()

   const [response, error, isLoading, setPostIdentity] = usePost(
      apiLink + '/api/users/update',
   )
   const [responsePassowrd, errorPassword, isLoadingPassword, setPostPassword] =
      usePost(apiLink + '/api/users/update_password')

   const { loggedUser } = useSessionContext()

   const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      // watch
   } = useForm()

   // const checkInputs = () => {
   //    // console.log(10)
   //    return (
   //       errors.first_name ||
   //       errors.email ||
   //       errors.username ||
   //       errors.telephone
   //    )
   //    // return true
   // }
   const handleClick = (data) => {
      console.log(10)
      setPostIdentity(data)
   }
   const handlePostPassword = () => {
      setPostPassword({
         user_id: userId,
         password: thePassword,
      })
   }
   const handlePassword = (pass) => {
      setThePassword(pass)
   }
   const handleConfirmPassword = (pass) => {
      setConfirmPassword(pass)
   }
   const handleIdentity = () => {
      setPassword(false)
      setIdentity(!identity)
   }
   const handleEditIdentity = () => {
      identity && setEditIdentity(!editIdentity)
   }
   const handleEditPassword = () => {
      setIdentity(false)
      setPassword(!password)
   }
   useEffect(() => {
      setValue('email', loggedUser.email)
      setValue('user_id', loggedUser.id)
      setValue('first_name', loggedUser.first_name)
      setValue('username', loggedUser.username)
      setValue('telephone', loggedUser.telephone)
      setUserId(loggedUser.id)
   }, [])

   useEffect(() => {
      if (response) {
         // console.log(response)
         // startSess
         startSession(response)
         openPopUp({
            title: '✅ Modification effectué avec succès.',
            content: `Modification effectué avec succès, @${response.username} ✨`,
            default: {
               title: '👊🏾',
               handler: closePopUp,
            },
         })
      }
      if (error) {
         // console.log(error)
         openPopUp({
            title: "⚠️ Erreur d'enregistrement",
            content: error.message,
            decline: {
               title: 'Réessayer',
            },
         })
      }
   }, [response, error])

   useEffect(() => {
      if (responsePassowrd) {
         openPopUp({
            title: '✅ Mot de passe modifié avec succès.',
            content: `Votre Mot de passe a été modifié avec succès ✨`,
            default: {
               title: '👊🏾',
               handler: () => {
                  setThePassword()
                  setConfirmPassword()
                  handleEditPassword()
                  closePopUp()
               },
            },
         })
      }
      if (errorPassword) {
         // console.log(error)
         openPopUp({
            title: "⚠️ Erreur d'enregistrement",
            content: errorPassword.message,
            decline: {
               title: 'Réessayer',
            },
         })
      }
   }, [responsePassowrd, errorPassword])
   return (
      <div className="flex w-full flex-col items-center justify-center px-4 pb-4">
         <div className="mt-4 flex w-full flex-row items-center gap-2 overflow-hidden rounded-lg bg-gray-200 p-4 dark:bg-gray-700 md:gap-4">
            <UserAvatar
               className={'flex h-14 w-14 sm:h-20 sm:w-20'}
               text="text-4 sm:text-6"
            />
            <div className="flex flex-col">
               <span className=" h- md:tex-6 font-bold text-gray-600 dark:text-slate-200 sm:text-5">
                  {loggedUser.first_name}
               </span>
               <div className="flex flex-row flex-wrap items-center sm:gap-2">
                  <span className="text-4 font-semibold text-gray-500 dark:text-gray-400 sm-max:text-3">
                     @{loggedUser.username},
                  </span>
                  {/* <span className="text-4 font-semibold text-gray-500 dark:text-gray-400">
                     |
                  </span> */}
                  <span className="text-4 font-semibold text-gray-500 dark:text-gray-400 sm-max:text-3">
                     {loggedUser.email}
                  </span>
               </div>
            </div>
         </div>
         <div className="flex w-full flex-row items-center justify-between p-2">
            <span
               onClick={() => handleIdentity()}
               className="cursor-pointer font-semibold text-gray-600 dark:text-gray-400"
            >
               Données Personnelles
            </span>
            {!identity && (
               <span
                  onClick={handleIdentity}
                  className="flex cursor-pointer items-center justify-center rounded-full p-2 hover:bg-gray-300 dark:hover:bg-dark"
               >
                  <HiChevronDown className="text-6 text-primary dark:text-gray-400" />
               </span>
            )}
            {identity && (
               <span
                  onClick={handleEditIdentity}
                  className="flex cursor-pointer items-center justify-center rounded-full p-2 hover:bg-gray-300 dark:hover:bg-dark"
               >
                  <HiPencil className="text-6 text-primary dark:text-gray-400" />
               </span>
            )}
         </div>
         {identity && !password && (
            <>
               <div className="flex w-full flex-col  gap-2 sm:gap-3">
                  <TextInput
                     {...register('user_id', {
                        required: true,
                        // minLength: 6,
                     })}
                     type="hidden"
                  />
                  <TextInput
                     rightIcon={HiUser}
                     {...register('first_name', {
                        required: true,
                        minLength: 6,
                     })}
                     color={errors.first_name && 'failure'}
                     id="name"
                     disabled={!editIdentity}
                     type="text"
                     placeholder="Votre nom"
                     // onChange={(event) => setEmail(event.target.value)}
                     required
                  />

                  <TextInput
                     rightIcon={HiMail}
                     {...register('email', {
                        required: true,
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        // minLength
                     })}
                     color={errors.email && 'failure'}
                     id="email"
                     type="mail"
                     disabled
                     placeholder="name@domain.com"
                     // onChange={(event) => setEmail(event.target.value)}
                     required
                  />
                  <TextInput
                     rightIcon={HiUser}
                     {...register('username', { required: true, minLength: 6 })}
                     color={errors.username && 'failure'}
                     id="last name"
                     disabled={!editIdentity}
                     type="text"
                     placeholder="Nom d'utilisateur"
                     // value={email}
                     // onChange={(event) => setEmail(event.target.value)}
                     required
                  />
                  <TextInput
                     rightIcon={HiPhone}
                     {...register('telephone', {
                        required: true,
                        // eslint-disable-next-line no-useless-escape
                        pattern: /^\+?\d[\d\s\.\-]+$/,
                     })}
                     color={errors.telephone && 'failure'}
                     id="telephone"
                     disabled={!editIdentity}
                     type="tel"
                     placeholder="Telephone"
                     // onChange={(event) => setEmail(event.target.value)}
                     required
                  />
               </div>
               <div className="flex w-full flex-col items-center justify-center gap-2 pt-2">
                  {editIdentity && (
                     <Button
                        disabled={
                           errors.first_name ||
                           errors.email ||
                           errors.username ||
                           errors.telephone
                        }
                        isProcessing={isLoading}
                        onClick={handleSubmit((e) => {
                           handleClick(e)
                        })}
                        className="w-full bg-primary hover:bg-dark"
                     >
                        Entegistrer
                     </Button>
                  )}
               </div>
            </>
         )}
         <div className="flex w-full flex-row items-center justify-between p-2">
            <span
               onClick={handleEditPassword}
               className="cursor-pointer font-semibold text-gray-600 dark:text-gray-400"
            >
               Modifier le Mot de Passe
            </span>
            <span
               onClick={handleEditPassword}
               className="flex cursor-pointer items-center justify-center rounded-full p-2 hover:bg-gray-300 dark:hover:bg-dark"
            >
               <HiPencil className="text-6 text-primary dark:text-gray-400" />
            </span>
         </div>
         {password && !identity && (
            <>
               <div className="flex w-full flex-col gap-2 sm:gap-3">
                  <TextInput
                     rightIcon={HiLockClosed}
                     // color={}
                     id="password"
                     type="password"
                     onChange={(e) => handlePassword(e.target.value)}
                     placeholder="Entrer le nouveau mot de Passe"
                     required
                  />
                  <TextInput
                     rightIcon={HiLockClosed}
                     color={thePassword !== confirmPassword ? 'failure' : ''}
                     id="confirm-password"
                     type="password"
                     onChange={(e) => handleConfirmPassword(e.target.value)}
                     placeholder="Confirmer le mot de Passe"
                     required
                  />
               </div>
               <div className="flex w-full flex-col items-center justify-center gap-2 pt-2">
                  <Button
                     disabled={thePassword !== confirmPassword}
                     isProcessing={isLoadingPassword}
                     onClick={handlePostPassword}
                     className="w-full bg-primary hover:bg-dark"
                  >
                     Modifier
                  </Button>
               </div>
            </>
         )}
      </div>
   )
}

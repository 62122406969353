// import { styled } from 'styled-components'
import { Button } from 'flowbite-react'
import { PopUpContext } from '../../utilis/Context'

export default function PopUp() {
   return (
      <PopUpContext.Consumer>
         {({ openPopUp, closePopUp, popUpState, view }) =>
            popUpState && (
               <div className="position fixed left-0 top-0 z-100 flex h-[100dvh] w-screen items-center justify-center bg-black bg-opacity-40 p-4">
                  <div className="flex w-100 flex-col gap-2 rounded-xl bg-darktext-white p-4 dark:bg-dark-400 md:w-120">
                     <span className="font-semibold text-dark dark:text-darktext-white md:text-5">
                        {popUpState.title
                           ? popUpState.title
                           : 'Operation Error'}
                     </span>
                     <span className="text-dark-400 dark:text-darktext">
                        {popUpState.content
                           ? popUpState.content
                           : 'Oups! Une erreur s`ait produite, veuillez verifier votre connexion internet puis réessayer'}
                     </span>
                     <Actions action={popUpState} close={closePopUp} />
                  </div>
               </div>
            )
         }
      </PopUpContext.Consumer>
   )
}

function Actions({ action, close }) {
   return (
      <div className="flex w-full flex-row items-center gap-2">
         {action.decline && (
            <Button
               outline
               color="failure"
               onClick={() => {
                  action.decline.handler && action.decline.handler()
                  close()
               }}
               className="w-full bg-red-700"
            >
               {action.decline.title}
            </Button>
         )}
         {action.accept && (
            <Button
               // outline
               color="blue"
               onClick={() => {
                  action.accept.handler && action.accept.handler()
                  close()
               }}
               className="w-full"
            >
               {action.accept.title}
            </Button>
         )}
         {action.default && (
            <Button
               color="dark"
               onClick={() => {
                  action.default.handler && action.default.handler()
                  close()
               }}
               className="w-full"
            >
               {action.default.title}
            </Button>
         )}
      </div>
   )
}

import { Tabs } from 'flowbite-react'
import {
   HiCalendar,
   HiCreditCard,
   HiLogin,
   HiOutlineTicket,
} from 'react-icons/hi'
import QRCode from 'react-qr-code'
// import { styled } from 'styled-components'
import { useScreenshot } from 'use-react-screenshot'

import { createRef, useEffect, useState } from 'react'
import { BiSupport } from 'react-icons/bi'
import { FaWhatsapp } from 'react-icons/fa'
import { MdDownloadForOffline } from 'react-icons/md'
import { apiLink, useApiContext } from '../../utilis/ApiContext'
import {
   getLoggedUser,
   useFormModalContext,
   useSessionContext,
} from '../../utilis/Context'
import { updateDataIfNeeded, useFetch } from '../../utilis/CustomHooks'
import { getDate } from '../../utilis/functions'
import CustomPopUp from '../CustomPopUp/CustomPopUp'
import { MyLoader } from '../Loader'

export default function MyTickets() {
   const {
      setReservations,
      setTickets,
      setPayments,
      reservations,
      tickets,
      payments,
   } = useApiContext()
   const { loggedUser } = useSessionContext()
   const [localTickets, isLoadingTickets] = useFetch(
      getLoggedUser() &&
         apiLink + '/api/users/tickets/' + getLoggedUser().user.id,
   )

   const [reservs, isLoadingReservations] = useFetch(
      getLoggedUser() &&
         apiLink + '/api/users/reservations/' + getLoggedUser().user.id,
   )
   const [localPayments, isLoadingLocalPayment] = useFetch(
      getLoggedUser() &&
         apiLink + '/api/users/payments/' + getLoggedUser().user.id,
   )

   useEffect(() => {
      updateDataIfNeeded(reservations, reservs, setReservations)
      updateDataIfNeeded(tickets, localTickets, setTickets)
      updateDataIfNeeded(payments, localPayments, setPayments)
   }, [reservs, localTickets, localPayments])

   return (
      <div className="flex min-h-[70dvh] w-full flex-col items-center pb-4">
         <Tabs
            className="w-full flex-nowrap justify-center sm:gap-4 "
            aria-label="Tabs with underline"
            style="underline"
         >
            <Tabs.Item
               active
               title="Billets"
               className="border-none focus:outline-none"
            >
               {tickets && tickets.length <= 0 && isLoadingTickets ? (
                  <MyLoader />
               ) : (
                  <div className="flex h-full w-full flex-col items-center gap-2 px-4">
                     {tickets && tickets.length > 0 && loggedUser ? (
                        tickets.map((ticket) => (
                           <Ticket ticket={ticket} key={ticket.code} />
                        ))
                     ) : (
                        <EmptyTickets title="Billets" />
                     )}
                  </div>
               )}
            </Tabs.Item>
            <Tabs.Item active title="Paiements">
               {payments && payments.length > 0 && isLoadingLocalPayment ? (
                  <MyLoader />
               ) : (
                  <div className="flex h-full w-full flex-col items-center gap-2 px-4">
                     {payments && payments.length > 0 && loggedUser ? (
                        payments.map((payment) => (
                           <Payment
                              payment={payment}
                              key={payment.id + payment.user_id}
                           />
                        ))
                     ) : (
                        <EmptyTickets title="Paiements" />
                     )}
                  </div>
               )}
            </Tabs.Item>
            {/* <Tabs.Item active title="Installement">
                  <div className="flex flex-col px-4 gap-2 w-full h-full items-center">
                     {tickets &&
                        tickets.map((ticket) => (
                           <Ticket ticket={ticket} key={ticket.code} />
                        ))}
                  </div>
               </Tabs.Item> */}
            <Tabs.Item active title="Réservations">
               {reservations &&
               reservations.length > 0 &&
               isLoadingReservations ? (
                  <MyLoader />
               ) : (
                  <div className="flex h-full w-full flex-col items-center gap-2 px-4">
                     {reservations && reservations.length > 0 && loggedUser ? (
                        reservations.map((reservation) => (
                           <Reservation
                              reservation={reservation}
                              key={reservation.id + reservation.user_id}
                           />
                        ))
                     ) : (
                        <EmptyTickets title="Reservations" />
                     )}
                  </div>
               )}
            </Tabs.Item>
         </Tabs>
      </div>
   )
}
// const Banner = styled.div`
//    background-image: url('${(props) => props.bg}');
//    background-position: start;
//    background-size: cover;
//    background-repeat: no-repeat;
//    width: 100%;
// `

function Ticket({ ticket }) {
   const [viewTicket, setViewTicket] = useState(false)
   const openView = () => {
      setViewTicket(true)
   }
   const closeView = () => {
      setViewTicket(false)
   }
   const ref = createRef(null)
   const ref1 = createRef(null)
   const ref2 = createRef(null)
   const [image, takeScreenshot] = useScreenshot({})
   useEffect(() => {
      // return takeScreenshot(ref.current)
      if (ref?.current && ref1?.current && ref2?.current) {
         ref1.current.className = 'mb-4'
         ref2.current.className = 'mb-4'
         viewTicket && takeScreenshot(ref.current)
         ref1.current.className = ''
         ref2.current.className = ''
      }
   }, [viewTicket, ref1, ref2])

   const getImage = () => {
      // console.log((ref1.current.className = 'mb-4'))
      const link = document.createElement('a')
      link.download = 'Ticket ' + ticket.code
      link.href = image
      link.click()
   }

   return (
      <>
         <div
            onClick={openView}
            className="flex w-full  cursor-pointer flex-row items-center justify-between gap-2 rounded-xl bg-darktext p-2 dark:bg-dark"
         >
            {/* <span className="material-icons-round text-dark dark:text-darktext p-2">ticket</span> */}
            <HiOutlineTicket className=" w-2/12  -rotate-45 p-2 text-12 text-dark dark:text-darktext md:w-3/12 md:text-16" />
            <div className="flex w-full flex-col gap-1 p-2 text-3 md:text-4">
               <span className="font-semibold text-dark dark:text-darktext-white">
                  {ticket.event.title}
               </span>
               <div className="justify- flex flex-row items-center gap-4">
                  <span className="font-semibold text-dark dark:text-darktext-white">
                     {ticket?.name_holder}
                  </span>
                  <span className="hidden font-semibold text-dark dark:text-darktext-white md:inline">
                     |
                  </span>
                  <span className=" font-semibold text-dark dark:text-darktext-white">
                     {ticket?.ticket_category.price +
                        ' ' +
                        ticket?.ticket_category.currency}
                  </span>
                  <span className="hidden font-semibold text-dark dark:text-darktext-white md:inline">
                     |
                  </span>
                  <span className="font-semibold capitalize text-dark dark:text-darktext-white md-max:hidden">
                     {getDate(ticket?.event?.date)}
                  </span>
               </div>
            </div>
            <span className="material-icons-round w-2/12 p-2 text-center font-semibold dark:text-darktext">
               info
            </span>
         </div>
         <CustomPopUp
            state={viewTicket}
            close={closeView}
            title={'Mon Billet'}
            rightBtn={
               ticket?.verified !== 1 && (
                  <MdDownloadForOffline
                     onClick={getImage}
                     className="cursor-pointer text-8 text-gray-600 dark:text-slate-200"
                  />
               )
            }
         >
            <div className="flex w-full flex-col items-center justify-center gap-2 md:p-4">
               <div
                  ref={ref}
                  className=" flex w-full flex-col items-center gap-2 rounded-xl bg-darktext-white"
               >
                  {/* <Banner
                     bg={
                        // ticket?.event
                        //    ? eventAssetsLink + ticket?.event?.banner
                        //    :
                        img
                     }
                     className="w-full h-40 rounded-t-xl overflow-hidden"
                  >
                     <div className="bg-green-500 ml-68 mt-6 items-center justify-center rotate-45 flex p-2 w-40">
                        <span className="text-white font-semibold">Activé</span>
                     </div>
                  </Banner> */}
                  <div className="flex w-full flex-col gap-2 p-6 pb-2">
                     <div className="flex flex-row items-center justify-between">
                        <div className=" flex items-center justify-center  rounded-xl bg-gradient-to-b from-indigo-700 to-indigo-500 px-2 font-semibold text-darktext-white opacity-60 dark:bg-opacity-60">
                           <span ref={ref1} className="">
                              {' '}
                              {ticket?.event?.category?.name}
                           </span>
                        </div>
                        {ticket?.verified === 1 ? (
                           <div className=" flex items-center justify-center rounded-xl bg-opacity-25 bg-gradient-to-r from-emerald-700 to-green-500 px-2 font-semibold text-darktext-white dark:bg-opacity-60">
                              <span ref={ref2} className="">
                                 {' '}
                                 {'Verifié'}
                              </span>
                           </div>
                        ) : (
                           <div className=" flex items-center justify-center rounded-xl bg-opacity-25 bg-gradient-to-r from-indigo-600 to-pink-500 px-2 font-semibold text-darktext-white dark:bg-opacity-60">
                              <span ref={ref2} className="">
                                 {' '}
                                 {'Activé'}
                              </span>
                           </div>
                        )}
                     </div>
                     <span className="items-left flex w-full flex-col gap-1 border-b-2 border-gray-400 pb-4 text-5 font-bold text-dark  md:text-6">
                        <span>{ticket.event.title}</span>
                        <span className="text-4 font-medium text-gray-400">
                           Montrez ce Billet à l'entrée
                        </span>
                     </span>
                  </div>
                  {/* <div className="w-full">
                     <span className="text-dark flex flex-col items-left justify-center gap-2 text-5 w-full md:text-6 text-center font-bold">
                        <span>{ticket.event.title}</span>
                     </span>
                  </div> */}
                  <div className="flex w-full flex-col px-6  ">
                     <div className="flex w-full justify-between  gap-2">
                        <div className="flex w-full flex-col">
                           <span className=" text-gray-600"> Nom : </span>
                           <span className="font-bold  text-gray-700 md:text-5">
                              {ticket.name_holder}
                           </span>
                        </div>
                        <div className="flex w-full flex-col self-start ">
                           <span className=" text-gray-600"> Place :</span>
                           <span className="font-bold  text-gray-700 md:text-5">
                              {ticket.spot}
                           </span>
                        </div>
                     </div>
                     <div className="flex w-full justify-between gap-2">
                        <div className="flex w-full flex-col  self-end">
                           <span className=" text-gray-600"> Type :</span>
                           <span className="font-bold  text-gray-700 md:text-5">
                              {ticket.ticket_category.type}
                           </span>
                        </div>
                        <div className="flex w-full flex-col">
                           <span className=" text-gray-600"> Prix : </span>
                           <span className="font-bold  text-gray-700 md:text-5">
                              {ticket.ticket_category.price +
                                 ' ' +
                                 ticket.ticket_category.currency}
                           </span>
                        </div>
                     </div>
                     <div className="flex w-full justify-between gap-2">
                        <div className="flex w-full flex-col">
                           <span className=" text-gray-600"> Date : </span>
                           <span className="font-bold  capitalize text-gray-700 md:text-5">
                              {getDate(ticket?.event?.date)}
                           </span>
                        </div>
                        <div className="flex w-full flex-col  self-end">
                           <span className=" text-gray-600"> Heure :</span>
                           <span className="font-bold  text-gray-700 md:text-5">
                              {ticket.event.start_time}
                           </span>
                        </div>
                     </div>
                     <div className="flex w-full justify-between gap-2">
                        <div className="flex w-full flex-col">
                           <span className=" text-gray-600"> Lieu : </span>
                           <span className="font-bold  text-gray-700 md:text-5">
                              {ticket?.event?.venue}
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="flex w-full items-center justify-between">
                     <span className="h-8 w-4 rounded-r-full bg-black p-2"></span>
                     <hr className="w-full flex-1 gap-4 border border-dashed border-gray-700" />
                     <span className="h-8 w-4 rounded-l-full bg-black p-2"></span>
                  </div>

                  <div className="flex w-full flex-col items-center justify-center gap-2 pb-6">
                     <div className=" w-40 md:w-60 ">
                        <QRCode
                           size={256}
                           style={{
                              height: 'auto',
                              maxWidth: '100%',
                              width: '100%',
                           }}
                           value={ticket.code}
                           viewBox={`0 0 256 256`}
                           level="H"
                        />
                     </div>
                     <span className=" text-5 font-semibold text-black">
                        #{ticket?.code}
                     </span>
                  </div>
               </div>

               {/* <Button
                  className="bg-primary w-full mx-12 flex flex-col gap-4"
                  onClick={getImage}
               >
                  <HiDownload className="text-6" />
                  <span>Download Ticket</span>
               </Button> */}
            </div>
         </CustomPopUp>
      </>
   )
}
function Reservation({ reservation }) {
   const [viewTicket, setViewTicket] = useState(false)
   const openView = () => {
      setViewTicket(true)
   }
   const closeView = () => {
      setViewTicket(false)
   }

   return (
      <>
         <div
            onClick={openView}
            className="flex w-full  cursor-pointer flex-row items-center gap-2 rounded-xl bg-darktext px-2 dark:bg-dark md:justify-between"
         >
            {/* <span className="material-icons-round text-dark dark:text-darktext p-2">ticket</span> */}
            <HiCalendar className=" w-2/12 p-2 text-12 text-dark dark:text-darktext md:w-3/12 md:text-16" />
            <div className="flex w-full flex-col gap-1 p-2">
               <div className="flex w-full flex-col gap-1 p-2 sm-max:text-3">
                  <span className="text-gray-600 dark:text-darktext-white">
                     {reservation?.event.title}
                  </span>
                  <div className="flex flex-1 flex-row items-center justify-between">
                     {/* <span className="font-semibold hidden md:inline text-dark dark:text-darktext-white f">
                        Nom : {reservation?.name_holder}
                     </span>
                     <span className="font-semibold hidden md:inline text-dark dark:text-darktext-white">
                        |
                     </span> */}
                     <span className="font-semibold text-dark dark:text-darktext-white">
                        {reservation?.name_holder}
                     </span>
                     <span className="hidden font-semibold text-dark dark:text-darktext-white md:inline">
                        |
                     </span>
                     <span className=" font-semibold text-dark dark:text-darktext-white">
                        {reservation?.ticket_category.price +
                           ' ' +
                           reservation?.ticket_category.currency}
                     </span>
                     <span className="hidden font-semibold text-dark dark:text-darktext-white md:inline">
                        |
                     </span>
                     <span className="hidden font-semibold capitalize text-dark dark:text-darktext-white md:inline">
                        {getDate(reservation?.event?.date)}
                     </span>
                  </div>
               </div>
            </div>
            <span className="material-icons-round w-2/12 p-2 text-center font-semibold dark:text-darktext md:inline">
               info
            </span>
         </div>
         <CustomPopUp
            state={viewTicket}
            close={closeView}
            title={
               <div className="flex items-center justify-center gap-2">
                  <HiCalendar className="text-6 text-dark dark:text-slate-400 md:text-10" />
                  {' Ma Réservation '}
               </div>
            }
         >
            <div className="flex w-full flex-col items-center justify-center gap-2">
               <div
                  // ref={ref}
                  className=" flex w-full flex-col items-center gap-2 rounded-xl bg-darktext-white dark:bg-dark-400"
               >
                  <div className="flex w-full flex-col justify-center px-6 pt-4">
                     <span className="items- flex w-full flex-col gap-2 text-center text-5 font-bold text-dark dark:text-gray-200 md:text-6">
                        {/* <div className="flex gap-2 items-center justify-center">
                           <HiCalendar className="dark:text-slate-400 text-dark text-8 md:text-10" />
                           {' RESERVATION '}
                        </div> */}
                        {/* <span>{reservation.event.title}</span> */}
                        <span className="text-start">
                           {reservation.event.title}
                        </span>
                     </span>
                  </div>
                  {/* <div className=" w-50 md:w-60 ">
                     <QRCode
                        size={256}
                        style={{
                           height: 'auto',
                           maxWidth: '100%',
                           width: '100%',
                        }}
                        value={payment.code}
                        viewBox={`0 0 256 256`}
                        level="H"
                     />
                  </div> */}
                  {/* <div className="w-full flex justify-between items-center"> */}
                  {/* <span className="p-2 h-8 w-4 rounded-r-full bg-black"></span> */}
                  {/* <hr className="border border-dashed border-gray-700 w-full flex-1 gap-4" /> */}
                  {/* <span className="p-2 h-8 w-4 rounded-l-full bg-black"></span> */}
                  {/* </div> */}

                  <div className="flex w-full flex-col justify-center px-6">
                     <div className="flex w-full justify-between">
                        <div className="flex w-full flex-col">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Réference :{' '}
                           </span>
                           <span className="font-bold text-gray-700 dark:text-gray-200 md:text-5">
                              {reservation.code}({reservation.id})
                           </span>
                        </div>
                     </div>
                     <div className="flex w-full justify-between">
                        <div className="flex w-full flex-col">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Nom :{' '}
                           </span>
                           <span className="font-bold text-gray-700 dark:text-gray-200 md:text-5">
                              {reservation.name_holder}
                           </span>
                        </div>
                        <div className="flex w-full flex-col  self-end">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Place :
                           </span>
                           <span className="font-bold text-gray-700 dark:text-gray-200 md:text-5">
                              {reservation.spot}
                           </span>
                        </div>
                     </div>
                     <div className="flex w-full justify-between">
                        <div className="flex w-full flex-col  self-end">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Type :
                           </span>
                           <span className="font-bold text-gray-700 dark:text-gray-200 md:text-5">
                              {reservation.ticket_category.type}
                           </span>
                        </div>
                        <div className="flex w-full flex-col">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Prix :{' '}
                           </span>
                           <span className="font-bold text-gray-700 dark:text-gray-200 md:text-5">
                              {reservation.ticket_category.price +
                                 ' ' +
                                 reservation.ticket_category.currency}
                           </span>
                        </div>
                     </div>
                     <div className="flex w-full justify-between">
                        <div className="flex w-full flex-col">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Date :{' '}
                           </span>
                           <span className="font-bold capitalize text-gray-700 dark:text-gray-200 md:text-5">
                              {getDate(reservation?.event?.date)}
                           </span>
                        </div>
                        <div className="flex w-full flex-col  self-end">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Heure :
                           </span>
                           <span className="font-bold text-gray-700 dark:text-gray-200 md:text-5">
                              {reservation.event.start_time}
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="flex w-full items-center justify-between">
                     <span className="h-8 w-4 rounded-r-full bg-black p-2 dark:bg-dark"></span>
                     <hr className="w-full flex-1 gap-4 border border-dashed border-gray-800" />
                     <span className="h-8 w-4 rounded-l-full bg-black p-2 dark:bg-dark"></span>
                  </div>
                  <div className="flex min-h-5 w-full flex-col justify-center px-6 pb-4">
                     <div className="flex w-full justify-between">
                        <div className="flex w-full flex-col">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Statut :
                           </span>
                           {Number(reservation.verified) === 1 ? (
                              Number(reservation.approved) === 1 ? (
                                 <>
                                    <span className="font-semibold text-green-700 dark:text-green-400">
                                       {' '}
                                       Réservation Approuvée{' '}
                                    </span>
                                    <span className="font-semibold text-green-700 dark:text-green-400">
                                       {' '}
                                       Votre Réservation a été approuvée, vous
                                       pouvez{' '}
                                    </span>
                                 </>
                              ) : (
                                 <>
                                    <span className="text-red-500">
                                       {' '}
                                       Réservation Refusée
                                    </span>
                                    <span className="text-red-500">
                                       {' '}
                                       Votre Réservation n'a été approuvée, vous
                                       pouvez{' '}
                                    </span>
                                 </>
                              )
                           ) : (
                              <span className="font-semibold text-gray-700 dark:text-gray-200 md:text-5">
                                 {' '}
                                 Réservation en cours de traitement{' '}
                              </span>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
               <div className="p-2">
                  <Support
                     number="+243991097363"
                     message={
                        "Bonjour KwetuEvent, c'est concernant \n la Réservation  : " +
                        reservation.code +
                        '(' +
                        reservation.id +
                        ')'
                     }
                  />
               </div>
               {/* <Button
                  className="bg-primary w-full mx-12 flex flex-col gap-4"
                  // onClick={getImage}
               >
                  <HiDownload className="text-6" />
                  <span>Download Ticket</span>
               </Button> */}
            </div>
         </CustomPopUp>
      </>
   )
}
function Payment({ payment }) {
   const [viewTicket, setViewTicket] = useState(false)
   const openView = () => {
      setViewTicket(true)
   }
   const closeView = () => {
      setViewTicket(false)
   }

   return (
      <>
         <div
            onClick={openView}
            className="flex w-full  cursor-pointer flex-row items-center gap-2 rounded-xl bg-darktext  p-2 dark:bg-dark"
         >
            {/* <span className="material-icons-round text-dark dark:text-darktext p-2">ticket</span> */}
            <HiCreditCard className=" w-2/12 p-2 text-12 text-dark dark:text-darktext md:w-3/12 md:text-16" />
            <div className="flex w-full flex-col gap-1 p-2 sm-max:text-3">
               <span className="text-gray-600 dark:text-darktext-white">
                  {payment.event.title}
               </span>
               <div className="flex w-full flex-1 flex-row items-center  justify-between">
                  <span className="font-semibold text-dark dark:text-darktext-white">
                     {payment?.name_holder}
                  </span>
                  <span className="hidden font-semibold text-dark dark:text-darktext-white md:inline">
                     |
                  </span>
                  <span className=" font-semibold text-dark dark:text-darktext-white">
                     {payment?.ticket_category.price +
                        ' ' +
                        payment?.ticket_category.currency}
                  </span>
                  <span className="hidden font-semibold text-dark dark:text-darktext-white md:inline">
                     |
                  </span>
                  <span className="hidden font-semibold capitalize text-dark dark:text-darktext-white md:inline">
                     {getDate(payment?.event?.date)}
                  </span>
               </div>
            </div>
            <span className="material-icons-round w-2/12 p-2 text-center font-semibold dark:text-darktext md:inline">
               info
            </span>
         </div>
         <CustomPopUp
            state={viewTicket}
            close={closeView}
            title={
               <div className="flex items-center justify-center gap-2">
                  <HiCreditCard className="text-6 text-dark dark:text-slate-400 md:text-10" />
                  {' Mon Paiement '}
               </div>
            }
         >
            <div className="flex w-full flex-col items-center justify-center gap-2 gap-2">
               <div
                  // ref={ref}
                  className=" flex w-full flex-col items-center gap-2 rounded-xl bg-darktext-white dark:bg-dark-400"
               >
                  <div className="flex w-full flex-col justify-center px-6 pt-4">
                     <span className="items- flex w-full flex-col gap-2 text-center text-5 font-bold text-dark dark:text-gray-200 md:text-6">
                        {/* <span>{reservation.event.title}</span> */}
                        <span className="text-start">
                           {payment.event.title}
                        </span>
                     </span>
                  </div>
                  {/* <div className=" w-50 md:w-60 ">
                     <QRCode
                        size={256}
                        style={{
                           height: 'auto',
                           maxWidth: '100%',
                           width: '100%',
                        }}
                        value={payment.code}
                        viewBox={`0 0 256 256`}
                        level="H"
                     />
                  </div> */}
                  {/* <div className="w-full flex justify-between items-center"> */}
                  {/* <span className="p-2 h-8 w-4 rounded-r-full bg-black"></span> */}
                  {/* <hr className="border border-dashed border-gray-700 w-full flex-1 gap-4" /> */}
                  {/* <span className="p-2 h-8 w-4 rounded-l-full bg-black"></span> */}
                  {/* </div> */}

                  <div className="flex w-full flex-col justify-center px-6">
                     <div className="flex w-full justify-between">
                        <div className="flex w-full flex-col">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Réference :{' '}
                           </span>
                           <span className="font-bold text-gray-700 dark:text-gray-200 md:text-5">
                              {payment.code}({payment.id})
                           </span>
                        </div>
                     </div>
                     <div className="flex w-full justify-between">
                        <div className="flex w-full flex-col">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Nom :{' '}
                           </span>
                           <span className="font-bold text-gray-700 dark:text-gray-200 md:text-5">
                              {payment.name_holder}
                           </span>
                        </div>
                        <div className="flex w-full flex-col  self-end">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Place :
                           </span>
                           <span className="font-bold text-gray-700 dark:text-gray-200 md:text-5">
                              {payment.spot}
                           </span>
                        </div>
                     </div>
                     <div className="flex w-full justify-between">
                        <div className="flex w-full flex-col  self-end">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Type :
                           </span>
                           <span className="font-bold text-gray-700 dark:text-gray-200 md:text-5">
                              {payment.ticket_category.type}
                           </span>
                        </div>
                        <div className="flex w-full flex-col">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Prix :{' '}
                           </span>
                           <span className="font-bold text-gray-700 dark:text-gray-200 md:text-5">
                              {payment.ticket_category.price +
                                 ' ' +
                                 payment.ticket_category.currency}
                           </span>
                        </div>
                     </div>
                     <div className="flex w-full justify-between">
                        <div className="flex w-full flex-col">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Date :{' '}
                           </span>
                           <span className="font-bold capitalize text-gray-700 dark:text-gray-200 md:text-5">
                              {getDate(payment?.event?.date)}
                           </span>
                        </div>
                        <div className="flex w-full flex-col  self-end">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Heure :
                           </span>
                           <span className="font-bold text-gray-700 dark:text-gray-200 md:text-5">
                              {payment.event.start_time}
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="flex w-full items-center justify-between">
                     <span className="h-8 w-4 rounded-r-full bg-black p-2 dark:bg-dark"></span>
                     <hr className="w-full flex-1 gap-4 border border-dashed border-gray-800" />
                     <span className="h-8 w-4 rounded-l-full bg-black p-2 dark:bg-dark"></span>
                  </div>
                  <div className="flex min-h-5 w-full flex-col justify-center px-6 pb-4">
                     <div className="flex w-full justify-between">
                        <div className="flex w-full flex-col">
                           <span className="text-gray-600 dark:text-gray-400">
                              {' '}
                              Statut :
                           </span>
                           {Number(payment.verified) === 1 ? (
                              Number(payment.approved) === 1 ? (
                                 <>
                                    <span className="font-semibold text-green-700 dark:text-green-400">
                                       {' '}
                                       Paiement Approuvé{' '}
                                    </span>
                                    <span className="font-semibold text-green-700 dark:text-green-400">
                                       {' '}
                                       Votre Billet est disponible dans la
                                       section Billets.
                                    </span>
                                 </>
                              ) : (
                                 <>
                                    <span className="text-red-500">
                                       {' '}
                                       Paiement Refusé
                                    </span>
                                    <span className="text-red-500">
                                       {' '}
                                       Paiement Refusé
                                    </span>
                                 </>
                              )
                           ) : (
                              <span className="font-semibold text-gray-700 dark:text-gray-200 md:text-5">
                                 {' '}
                                 Paiement en cours de Vérification{' '}
                              </span>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
               <div className="p-2">
                  <Support
                     number="+243991097363"
                     message={
                        "Bonjour KwetuEvent, c'est concernant \n le Paiement : " +
                        payment.code +
                        '(' +
                        payment.id +
                        ')'
                     }
                  />
               </div>
               {/* <Button
                  className="bg-primary w-full mx-12 flex flex-col gap-4"
                  // onClick={getImage}
               >
                  <HiDownload className="text-6" />
                  <span>Download Ticket</span>
               </Button> */}
            </div>
         </CustomPopUp>
      </>
   )
}

function EmptyTickets({ title }) {
   const { loggedUser } = useSessionContext()
   const { changeForm } = useFormModalContext()

   return (
      <>
         <div
            onClick={() => {
               !loggedUser && changeForm('connexion')
            }}
            className="flex w-full  cursor-pointer flex-row items-center justify-between gap-2 rounded-xl bg-darktext p-2 dark:bg-dark"
         >
            {/* <span className="material-icons-round text-dark dark:text-darktext p-2">ticket</span> */}
            {loggedUser ? (
               <HiOutlineTicket className=" w-2/12 p-2 text-12 text-dark dark:text-darktext md:w-3/12 md:text-16" />
            ) : (
               <HiLogin className=" w-2/12 p-2 text-12 text-dark dark:text-darktext md:w-3/12 md:text-16" />
            )}
            <div className="flex w-full flex-col gap-1 p-2">
               <span className="font-bold text-dark dark:text-darktext-white">
                  {loggedUser && "Vous n'avez des " + title + " pour l'instant"}
               </span>
               <div className="justify- flex flex-row items-center gap-4">
                  <span className="text-3 font-light text-dark dark:text-darktext-white sm:text-4">
                     {!loggedUser && (
                        <>
                           Vous devez vous connecter à votre compte pour voir
                           vos {title + '  '}
                           <span className="text-blue-500 underline">
                              Connexion ..{' '}
                           </span>
                        </>
                     )}
                  </span>
                  <span className="w-ful f font-semibold text-dark dark:text-darktext-white"></span>
               </div>
            </div>
            <span className="material-icons-round w-2/12 p-2 text-center font-semibold dark:text-darktext">
               info
            </span>
         </div>
      </>
   )
}

export function Support({ number, message }) {
   return (
      <div className="m-2 flex flex-row items-center justify-between gap-2 rounded-lg border p-4 dark:border-gray-600">
         <div
            // onClick={handleEdit}
            className="hidden cursor-pointer items-center justify-center rounded-full p-2 dark:hover:bg-dark-400 md:flex"
         >
            <BiSupport className="text-6 dark:text-white" />
         </div>
         <div className="flex-flex-col gap-2">
            <div className="space-y-1 font-medium dark:text-white">
               <div>Vous avez besoin d'aide ?</div>
               <div className="flex text-sm text-gray-500 dark:text-gray-400">
                  <span>
                     Entrez en contact avec notre service client maintenant.{' '}
                  </span>
                  {/* <span> {loggedUser?.telephone}</span> */}
               </div>
            </div>
         </div>
         <a
            target="_blank"
            href={
               'https://api.whatsapp.com/send?phone=' +
               number +
               "&text='" +
               message +
               "'"
            }
            rel="noreferrer"
            // onClick={handleEdit}
            className="flex cursor-pointer items-center justify-center rounded-full p-4 hover:bg-darktext hover:text-white dark:hover:bg-dark-400"
         >
            <FaWhatsapp className="text-6 dark:text-white" />
         </a>
      </div>
   )
}

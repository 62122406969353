import { HiDotsHorizontal } from 'react-icons/hi'
import { useApiContext } from '../../utilis/ApiContext'
import { useCategoryModalContext } from '../../utilis/Context'
import { randomKey } from '../../utilis/functions'
import SectionCard from '../SectionCard'

export default function Section({ title }) {
   const { hots } = useApiContext()
   const { changeCategory } = useCategoryModalContext()

   // useEffect(() => {
   //    refreshHots && refreshHots()
   // }, [])
   return (
      <div className="shadow-s flex h-auto w-full flex-col gap-2 rounded-2xl bg-white px-2 dark:bg-dark-400 sm:px-4 sm:pt-4 md:px-6">
         <div className="mb-1 flex flex-row justify-between">
            <span
               id="home"
               className="rounded-xl bg-white px-3 py-1 font-semibold dark:bg-dark dark:text-darktext "
            >
               {title}
            </span>
            <div
               onClick={() => changeCategory('Événements Populaires')}
               className="flex cursor-pointer items-center rounded-xl border bg-bg px-2 py-1 font-semibold hover:bg-white dark:border-none dark:bg-dark dark:text-darktext dark:hover:text-dark md-max:text-3"
            >
               {' '}
               <HiDotsHorizontal />
            </div>
         </div>
         <div className="min-w-screen flex flex-row items-center gap-5 overflow-x-auto px-2 ">
            {hots && hots?.length > 0 ? (
               hots.map((event) => (
                  <SectionCard
                     event={event}
                     key={event?.id + randomKey() + 'evs'}
                  />
               ))
            ) : (
               <SectionCard />
            )}
         </div>
      </div>
   )
}

import { FaCarCrash } from 'react-icons/fa'
import { FaServer } from 'react-icons/fa6'
import { HiRefresh } from 'react-icons/hi'
import './index.css'
import Logo from './Logo'
export default function Brand({ error, crashed, setError, refreshAll }) {
   // const { refreshAll } = useApiContext()
   return (
      <div className="flex h-[100dvh] w-full flex-col items-center justify-between gap-2 p-8">
         <div></div>
         <div className="flex flex-col items-center justify-center gap-2 p-5">
            {!crashed && !error && (
               <div className="flex w-full items-center justify-center gap-4 ">
                  <Logo className={'loaderz'} />
                  {/* <DarkThemeToggle /> */}
               </div>
            )}
            {error && (
               <div className="flex max-w-md flex-col items-center gap-2">
                  <FaServer className="animate-pulse text-10 text-red-700" />
                  <span className="text-light text-center font-semibold text-gray-DEFAULT-600 dark:text-slate-400">
                     Impossible de vous connecter au server, Veuillez verifier
                     votre connexion internet puis réessayer.
                  </span>
                  <HiRefresh
                     onClick={() => {
                        setError()
                        refreshAll()
                     }}
                     className="cursor-pointer text-6 text-gray-DEFAULT-800 dark:text-slate-200"
                  />
               </div>
            )}
            {/* {!error && !crashed && (
               <div className="flex items-center gap-2">
                  <span className="text-light text-center dark:text-slate-400 ">
                     Là où les finances prennent vie.
                  </span>
               </div>
            )} */}
            {crashed && (
               <div className="flex max-w-md flex-col items-center gap-2">
                  <FaCarCrash className="animate-pulse text-10 text-red-700" />
                  <span className="text-light text-center font-semibold text-gray-DEFAULT-600 dark:text-slate-400">
                     Oups! Une erreur s'ait produite, KwetuEvent a cessée de
                     fonctionner, Veuillez redémarrer.
                  </span>
                  <HiRefresh
                     onClick={() => window.location.reload()}
                     className="cursor-pointer text-6 text-gray-DEFAULT-800 dark:text-slate-200"
                  />
               </div>
            )}
            {/* {error ? '' : !crashed && <Spinner />} */}
         </div>
         <div className="flex flex-col items-center">
            {/* <Logo /> */}
            <span className="text-center font-light text-gray-500 dark:text-slate-600">
               from
            </span>
            <span className="text-center text-5 font-semibold text-gray-600 dark:text-slate-300">
               KwetuTech
            </span>
         </div>
      </div>
   )
}
export function BrandCrashed({ error, crashed }) {
   // const { refreshAll } = useApiContext()
   return (
      <div className="flex h-[100dvh] w-full flex-col items-center justify-between gap-2 p-8">
         <div></div>
         <div className="flex flex-col items-center justify-center gap-2 p-5">
            {!crashed && !error && (
               <div className="flex w-full items-center justify-center gap-4 ">
                  <Logo className={'loaderz'} />
                  {/* <DarkThemeToggle /> */}
               </div>
            )}
            {error && (
               <div className="flex max-w-md flex-col items-center gap-2">
                  <FaServer className="animate-pulse text-10 text-red-700" />
                  <span className="text-light text-center font-semibold text-gray-DEFAULT-600 dark:text-slate-400">
                     Impossible de vous connecter au server, Veuillez verifier
                     votre connexion internet puis réessayer.
                  </span>
                  <HiRefresh
                     onClick={() => window.location.reload()}
                     className="cursor-pointer text-6 text-gray-DEFAULT-800 dark:text-slate-200"
                  />
               </div>
            )}
            {/* {!error && !crashed && (
               <div className="flex items-center gap-2">
                  <span className="text-light text-center dark:text-slate-400 ">
                     Là où les finances prennent vie.
                  </span>
               </div>
            )} */}
            {crashed && (
               <div className="flex max-w-md flex-col items-center gap-2">
                  <FaCarCrash className="animate-pulse text-10 text-red-700" />
                  <span className="text-light text-center font-semibold text-gray-DEFAULT-600 dark:text-slate-400">
                     Oups! Une erreur s'ait produite, l'application a céssée de
                     fonctionner, Veuillez rédemarer.
                  </span>
                  <HiRefresh
                     onClick={() => window.location.reload()}
                     className="cursor-pointer text-6 text-gray-DEFAULT-800 dark:text-slate-200"
                  />
               </div>
            )}
            {/* {error ? '' : !crashed && <Spinner />} */}
         </div>
         <div className="flex flex-col items-center">
            {/* <Logo /> */}
            <span className="text-center font-light text-gray-200 dark:text-slate-600">
               from
            </span>
            <span className="text-center text-5 font-semibold text-gray-600 dark:text-slate-300">
               KwetuTech
            </span>
         </div>
      </div>
   )
}

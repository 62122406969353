import { Button, Textarea, TextInput } from 'flowbite-react'
import { useEffect } from 'react'
import { HiMail, HiPhone, HiUser } from 'react-icons/hi'

import { Controller, useForm } from 'react-hook-form'
import { apiLink } from '../../utilis/ApiContext'
import { useFormModalContext, usePopUpContext } from '../../utilis/Context'
import { usePost } from '../../utilis/CustomHooks'
import { Support } from '../MyTickets'
export default function Contact({ handler }) {
   const [response, error, isLoading, setPost] = usePost(
      apiLink + '/auth/contact',
   )
   const {
      handleSubmit,
      // formState: { errors },
      reset,
      control,
   } = useForm({
      rules: { required: true },
   })
   const { openPopUp } = usePopUpContext()
   const { closeModal } = useFormModalContext()
   const handleClick = (data) => {
      // console.log(data)
      setPost(data)
   }

   useEffect(() => {
      if (response === 'success') {
         openPopUp({
            title: 'Message envoyé avec succès',
            content:
               'Votre Message a été envoyé avec succès, merci de nous avoir contacté!',
            accept: {
               title: '👊🏾',
               handler: () => {
                  reset()
                  closeModal()
               },
            },
         })
      }
      if (error) {
         openPopUp({
            title: '⚠️ Message non envoyé',
            content:
               "OOPS! une erreur s'ait produite, veuillez verifier votre connexion internet puis réessayer ",
            decline: {
               title: 'Réessayer',
            },
         })
      }
   }, [response, error])

   return (
      <div className="flex w-full flex-col items-center gap-2 md:gap-4">
         <div className="px-2">
            <Support
               number={'+243991097363'}
               message={"Bonjour KwetuEvent, j'aurais besoin d'aide"}
            />
         </div>
         <form
            onSubmit={handleSubmit((data) => handleClick(data))}
            className="flex w-full flex-col items-center justify-center gap-2 px-4 pb-4"
         >
            <span className="dark:text-gray-600">ou</span>
            <div className="flex flex-row items-center gap-2">
               <span className="material-icons-round text-6 font-medium text-dark dark:text-primary dark:text-white md:text-8">
                  chat
               </span>
               <span className="font-bold dark:text-gray-200">
                  Laissez nous un Message
               </span>
            </div>
            <div className="flex w-full flex-col gap-2 sm:gap-3">
               <Controller
                  control={control}
                  name="names"
                  render={({ field }) => (
                     <TextInput
                        {...field}
                        rightIcon={HiUser}
                        id="names"
                        type="text"
                        placeholder="Votre nom"
                        required
                     />
                  )}
               />
               <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                     <TextInput
                        {...field}
                        rightIcon={HiMail}
                        id="names"
                        type="mail"
                        placeholder="Votre adresse mail"
                        required
                     />
                  )}
               />
               <Controller
                  control={control}
                  name="telephone"
                  render={({ field }) => (
                     <TextInput
                        {...field}
                        rightIcon={HiPhone}
                        id="names"
                        placeholder="Votre numero de téléphone"
                        required
                     />
                  )}
               />
               <Controller
                  control={control}
                  name="message"
                  render={({ field }) => (
                     <Textarea
                        {...field}
                        id="message"
                        placeholder="Tapez votre  message ici..."
                        required
                        rows={6}
                     />
                  )}
               />
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-2">
               <Button
                  type="submit"
                  isProcessing={isLoading}
                  className="w-full bg-primary hover:bg-dark"
               >
                  Envoyer le Message
               </Button>
            </div>
         </form>
      </div>
   )
}

import { useThemeMode } from 'flowbite-react'
import { FaEye, FaHeart } from 'react-icons/fa'
import { HiLocationMarker } from 'react-icons/hi'
import { styled } from 'styled-components'
import bg from '../../assets/imgs/bg.png'
import bg_dark from '../../assets/imgs/bg_dark.png'
import { eventAssetsLink } from '../../utilis/ApiContext'
import { useEventModalContext } from '../../utilis/Context'
import { getDate } from '../../utilis/functions'

const Banner = styled.div`
   background-image: url(${(props) => props.bg});
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
`
export default function SectionCard({ event }) {
   const { changeEvent } = useEventModalContext()
   const { mode } = useThemeMode()
   return (
      <Banner
         bg={mode === 'light' ? bg : bg_dark}
         className="h-60 w-96 cursor-pointer rounded-xl md:h-72"
      >
         <Banner
            onClick={() => event && changeEvent(event)}
            bg={event?.banner && eventAssetsLink + event?.banner}
            className="h-60 w-96 cursor-pointer rounded-xl md:h-72"
         >
            <Description
               title={event?.title}
               venue={event?.venue}
               date={event?.date}
               time={event?.start_time}
               category={event?.category}
               likes={event?.likes}
               views={event?.views}
            />
         </Banner>
      </Banner>
   )
}

function Description({ title, date, venue, category, views, likes }) {
   return (
      <div className="flex h-full w-full flex-col justify-between gap-2 rounded-xl p-4 dark:text-dark">
         <div className="flex items-start justify-between ">
            <span className="inline rounded-full border border-darktext-white bg-black bg-opacity-25 px-2 py-1 font-semibold text-darktext-white dark:border-dark dark:bg-darktext dark:bg-opacity-60 dark:text-dark sm-max:text-3">
               {category && category?.name}
            </span>
            <div className="flex flex-col items-center justify-center rounded-xl bg-white bg-gradient-to-r  from-indigo-600 to-blue-700 px-4 py-1 dark:bg-dark-400">
               <span className="text-6 font-bold text-darktext-white">
                  {date ? getDate(date).split(' ')[1] : '01'}
               </span>
               <span className="capitalize text-slate-200">
                  {date ? getDate(date).split(' ')[2] : 'Jan'}
               </span>
            </div>
         </div>
         <div className="flex h-24  flex-row items-center justify-between gap-2 rounded-xl bg-white p-4 dark:bg-dark dark:text-slate-200">
            <div className="">
               <span className="h-1/2 overflow-hidden font-semibold">
                  {title ? title : 'KwetuEvent'}
               </span>
               <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                     <HiLocationMarker />
                     <span className="sm-max:text-3">
                        {venue ? venue : 'Venue'}
                     </span>
                  </div>
               </div>
            </div>
            <div className="flex flex-row items-center gap-2">
               <div className="flex flex-col items-center justify-center rounded-xl bg-bg px-4 py-2 dark:bg-dark-400">
                  <FaEye className="text-4" /> {views}
               </div>
               <div className="flex flex-col items-center justify-center rounded-xl bg-bg   px-4 py-2 dark:bg-dark-400">
                  <FaHeart className="text-4 text-pink-600" /> {likes}
               </div>
            </div>
         </div>
      </div>
   )
}

// import './index.css'
// import NavBar from '../../components/NavBar'
import HomeBanner from '../../components/HomeBanner'
import Section from '../../components/Section'

import Posts from '../../components/Posts'
import SearchBar from '../../components/SearchBar'

import { useEffect } from 'react'
import { MyLoader } from '../../components/Loader'
import { useApiContext } from '../../utilis/ApiContext'
import { useNavContext } from '../../utilis/Context'
// import MyContentLoader from '../../components/ContentLoader'
// import Footer from '../../components/Footer'

export default function Home() {
   const { hots } = useApiContext()
   const { handler } = useNavContext()
   useEffect(() => {
      handler('Accueil')
   }, [])
   return (
      <div className="mb-2 mt-2 flex h-full  w-full max-w-screen-xl flex-col items-center md:mt-5">
         <div className="flex w-full max-w-screen-xl flex-col items-center gap-2 md:gap-4">
            <SearchBar />
            <div className="p- flex w-full flex-col gap-5 bg-white dark:bg-dark-400 sm:rounded-xl">
               <HomeBanner />
               {hots ? (
                  <>
                     <Section title="✨ Événements Populaires" />
                     <Posts />
                  </>
               ) : (
                  <MyLoader />
               )}
            </div>
         </div>
      </div>
   )
}

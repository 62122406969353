import { useEffect, useState } from 'react'

export function useScreenWidth() {
   const [width, setWidth] = useState(window.innerWidth)

   useEffect(() => {
      const handleResize = () => {
         setWidth(window.innerWidth)
      }

      window.addEventListener('resize', handleResize)

      return () => {
         window.removeEventListener('resize', handleResize)
      }
   }, [])

   return [width < 1200, width < 768]
}

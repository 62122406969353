// import { HiMail, HiUser } from 'react-icons/hi'

import { DarkThemeToggle } from 'flowbite-react'
import { useApiContext } from '../../utilis/ApiContext'
import {
   FormModalContext,
   useCartContext,
   useCategoryModalContext,
   useFormModalContext,
   usePopUpContext,
   useSessionContext,
} from '../../utilis/Context'

export default function Profile() {
   const { closeModal } = useFormModalContext()
   const { loggedUser, destroySession } = useSessionContext()
   const { logOut } = useApiContext()
   const { cart, openModal } = useCartContext()
   const { changeCategory } = useCategoryModalContext()
   const { openPopUp } = usePopUpContext()
   // const { changeForm } = useFormModalContext()
   return (
      <div className="flex w-full flex-col items-center gap-2 px-4 pb-4 pt-2">
         <Settings>
            <Setting
               form={loggedUser ? 'mon compte' : 'connexion'}
               icon={'person'}
               desc={
                  loggedUser ? '@' + loggedUser.username : 'Connectez-vous ici'
               }
               title="Mon Compte"
            />
            <Setting
               form={'contactez nous'}
               icon={'add'}
               title="Créez votre Billetterie en ligne"
            />
         </Settings>

         <Settings title={'Panier'}>
            {cart && cart.length > 0 ? (
               <Setting
                  onClick={openModal}
                  // form={loggedUser ? '' : connexion}
                  icon={'shopping_cart'}
                  desc={'Tous vos billets et réservations ici.'}
                  title="Mon Panier"
               />
            ) : (
               <Setting
                  onClick={() => changeCategory('tous les événements')}
                  // form={loggedUser ? '' : connexion}
                  icon={'shopping_cart'}
                  desc={
                     'Votre Panier est vider, achetez des billets ou réservez-en...'
                  }
                  title="Mon Panier"
               />
            )}
         </Settings>

         <Settings title={'Preferences'}>
            <Setting icon={'dark_mode'} title="Theme" handler={true} />
         </Settings>
         {/* <Settings title={'Notifications'}>
            <Setting
               icon={'notification_add'}
               title="Account Notifications"
               handler={true}
            />
            <Setting
               icon={'notifications_active'}
               title="Promotions"
               handler={true}
            />
            <Setting
               icon={'notifications_active'}
               title="Account Notifications"
               handler={true}
            />
         </Settings> */}
         <Settings title={'Account'}>
            <Setting
               form={'contactez nous'}
               icon={'chat'}
               title="Contactez Nous"
            />
            <Setting
               form={'contactez nous'}
               icon={'warning'}
               title="Signaler un problème"
            />

            <Setting
               onClick={() =>
                  openPopUp({
                     title: 'Déconnexion',
                     content: 'Voulez-vous vraiment vous déconnecter? ',
                     decline: {
                        title: 'Annuler',
                     },
                     accept: {
                        title: 'Déconnecter',
                        handler: () => {
                           logOut()
                           destroySession()
                           closeModal()
                           // openPopUp({
                           //    title: 'Déconnexion',
                           //    content: 'Vous avez été déconnecté avec succès? ',
                           //    default: {
                           //       title: 'Ok',
                           //       handler: () => {
                           //       },
                           //    },
                           // })
                        },
                     },
                  })
               }
               icon={'logout'}
               title="Déconnexion"
            />
         </Settings>
      </div>
   )
}

function Settings({ title, children }) {
   return (
      <div className="flex w-full cursor-pointer flex-col gap-2 border-b pb-2 text-dark-400 dark:border-dark dark:text-darktext">
         {title && (
            <span className="font-semibold text-dark dark:text-darktext">
               {title}
            </span>
         )}
         <div className="flex flex-col items-center">{children}</div>
      </div>
   )
}

function Setting({ icon, title, desc, handler, form, onClick }) {
   // const [value, setValue] = useState(false)
   return (
      <FormModalContext.Consumer>
         {({ changeForm }) => (
            <div
               onClick={() => {
                  form && changeForm(form)
                  onClick && onClick()
               }}
               className="flex w-full flex-row items-center gap-3 rounded-xl p-2 hover:bg-darktext dark:hover:bg-dark"
            >
               <span className="material-icons-round bg-slat00 dark:bg-drk rounded-full p-3 text-7 text-gray-600 dark:text-slate-200">
                  {icon}
               </span>
               <div className="gap flex w-full flex-col">
                  <span className="font-semibold text-gray-800 dark:text-darktext-white">
                     {title}
                  </span>
                  {desc && (
                     <span className="text-gray-400 dark:text-slate-400 sm-max:text-3 ">
                        {desc}
                     </span>
                  )}
               </div>
               {handler ? (
                  // <Radio
                  //    name={title}
                  //    value={value}
                  //    sendValue={setValue}
                  //    inputName={title}
                  // />
                  <DarkThemeToggle />
               ) : (
                  <span className="material-icons-round text-dark dark:text-darktext">
                     keyboard_arrow_right
                  </span>
               )}
            </div>
         )}
      </FormModalContext.Consumer>
   )
}

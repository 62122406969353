// import { CustomFlowbiteTheme } from 'flowbite-react';
import { Carousel, useThemeMode } from 'flowbite-react'
import { assetsLink, useApiContext } from '../../utilis/ApiContext'
// import { localBanners } from '../../utilis/Data'
import bg from '../../assets/imgs/bg.png'
import bg_dark from '../../assets/imgs/bg_dark.png'
import NextEvent from '../NextEvent'

const customTheme = {
   control: {
      base: 'hidden',
      icon: 'hidden',
   },
}

export default function HomeBanner() {
   // const data = localBanners
   const { banners } = useApiContext()
   const { mode } = useThemeMode()

   return (
      <div className="flex w-full flex-col items-center gap-5 px-4 pt-4 md:flex-row">
         <div className="h-60 w-full sm:h-64 md:w-7/12 lg:w-8/12 xl:h-85">
            <Carousel
               slideInterval={5000}
               // pauseOnHover
               theme={{ theme: customTheme }}
               className="rounded-lg bg-cover bg-center"
               style={{
                  backgroundImage:
                     mode === 'light'
                        ? 'url(' + bg + ')'
                        : 'url(' + bg_dark + ')',
               }}
            >
               {banners &&
                  banners.map((banner) => (
                     <img
                        src={assetsLink + banner.img}
                        key={banner.img}
                        alt="..."
                     />
                  ))}
            </Carousel>
         </div>
         <div className="flex w-full flex-col gap-4 md:w-5/12 lg:w-4/12">
            <NextEvent />
         </div>
      </div>
   )
}

import { Spinner } from 'flowbite-react'

export function MyLoader() {
   return (
      <div className="flex h-full w-full flex-col items-center justify-center p-8">
         <Spinner
            color="success"
            aria-label="Extra large spinner example"
            size="xl"
         />
      </div>
   )
}

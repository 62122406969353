import { useState } from 'react'
import styled from 'styled-components'
import { useApiContext } from '../../utilis/ApiContext'
import { useSessionContext } from '../../utilis/Context'
import { MyLoader } from '../Loader'

export default function Notification() {
   const { loggedUser } = useSessionContext()
   const { notifications, isLoadingNotif } = useApiContext()
   return loggedUser ? (
      <div className="flex min-h-full w-full flex-col items-center gap-2 p-4 text-dark dark:text-darktext">
         {isLoadingNotif ? (
            <MyLoader />
         ) : (
            notifications &&
            notifications?.length > 0 &&
            notifications?.map(({ title, icon, text, id }) => (
               <Notif
                  icon={icon}
                  title={title}
                  content={text}
                  key={id + icon + 'notifs'}
               />
            ))
         )}
         <Notif />
      </div>
   ) : (
      <div className="flex min-h-full w-full flex-col items-center gap-2 p-4 text-dark dark:text-darktext">
         {/* <Notif icon="person" title={'Account'} /> */}
      </div>
   )
}

const NotifDetails = styled.div`
   height: ${(props) => (props.expand ? '' : '64px')};
`

function Notif({ icon, content, title }) {
   const [expand, setExpand] = useState(false)
   const handleExpand = () => {
      // console.log(1)
      setExpand(!expand)
   }

   // const handleMove = () => {
   //    // console.log(10)
   // }

   return (
      <NotifDetails
         onClick={handleExpand}
         // onMouseMove={handleMove}
         expand={expand}
         className="flex w-full cursor-pointer flex-row gap-2 rounded-xl  bg-darktext p-2 dark:bg-dark"
      >
         <span className="material-icons-round flex h-14 w-14 items-center justify-center rounded-full text-8">
            {icon ? icon : 'notifications'}
         </span>
         <div className="flex h-full w-full flex-col overflow-hidden">
            <span className="font-semibold text-dark dark:text-darktext-white">
               {title ? title : 'Notification'}
            </span>
            <span className="sm-max:text-3">
               {content
                  ? content
                  : `Bienvenue sur KwetuEvent !
                     Nous sommes heureux de vous accueillir sur notre plateforme.
                     Découvrez des événements,Créez des souvenirs,Partagez des moments uniques.`}
            </span>
         </div>
         <span className="material-icons-round flex h-14 w-14 items-center justify-center rounded-full text-8">
            {expand ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
         </span>
      </NotifDetails>
   )
}

import { Link } from 'react-router-dom'
import { useNavContext } from '../../utilis/Context'

export default function NavIcon({ name, onClick, HiCon, link, rotate }) {
   // console.log(active)
   const { activeNav, handler } = useNavContext()
   // const { openModal } = useExpoloreModalContext()
   return (
      <Link
         to={link && link}
         onClick={() => {
            link && handler(name)
            onClick && onClick()
         }}
         className="flex-1 cursor-pointer pb-4 pt-2 "
      >
         <div
            // to={link && link}
            className={
               activeNav === name
                  ? ' flex flex-col items-center justify-center text-dark   dark:text-white'
                  : ' flex flex-col items-center justify-center text-gray-500 dark:text-slate-300 '
            }
         >
            {HiCon ? (
               <HiCon className={rotate ? ' -rotate-45 text-8 ' : 'text-8'} />
            ) : (
               <span className="material-icons-round text-6">{name}</span>
            )}
         </div>
      </Link>
   )
}

/* eslint-disable no-prototype-builtins */
import axios from 'axios'
import { useEffect, useState } from 'react'
import { getLoggedUser } from '../Context'

export const updateDataIfNeeded = (
   currentData,
   newData,
   setData,
   setError = null,
) => {
   if (JSON.stringify(currentData) !== JSON.stringify(newData)) {
      // console.log(newData)
      newData && setData(newData)
      setError && setError()
   }
}

export function useFetch(link, waitRefresh = false) {
   const [wait, setWait] = useState(waitRefresh)
   const [data, setData] = useState()
   const [isLoading, setIsLoading] = useState(true)
   const [error, setError] = useState()
   const [headers, setHeaders] = useState({
      'Content-Type': 'application/json',
      Authorization: getLoggedUser() ? getLoggedUser().token : ' ',
   })
   const fetchData = async (link, headers) => {
      setError()
      try {
         const response = await axios.get(link, { headers })
         setIsLoading(false)
         return response.data
      } catch (err) {
         // console.log(err)
         err &&
            err.response &&
            setError({
               code: err.response.status,
               message: err.response.data,
            })
         err.code && setError(err)
         setIsLoading(false)
      }
   }

   const fetchDataAndCompare = async () => {
      try {
         const newData = await fetchData(link, headers)
         updateDataIfNeeded(data, newData, setData, setError)
      } catch (error) {
         // setError()
         console.log(error)
      }
   }

   useEffect(() => {
      getLoggedUser() ? setHeaders(getLoggedUser().token) : setHeaders('')
   }, [wait, error])

   useEffect(() => {
      data && setError()
   }, [data])

   useEffect(() => {
      if (!wait) {
         fetchDataAndCompare()

         // Set up a timer to fetch data periodically (every 5 minutes)
         const intervalId = setInterval(fetchDataAndCompare, 2 * 60 * 1000)

         // Clear the interval when the component is unmounted
         return () => clearInterval(intervalId)
      }
   }, [])

   const refresh = () => {
      setWait(false)
      setError()
      fetchDataAndCompare()
   }
   const clear = () => {
      setData()
   }

   return [data, isLoading, error, refresh, clear]
}

export function usePost(link) {
   const [response, setResponse] = useState()
   const [error, setError] = useState()
   const [isLoading, setIsLoading] = useState()
   const [post, setPost] = useState()
   const [token, setToken] = useState()

   // setLoading(true)

   useEffect(() => {
      const session = getLoggedUser()
      if (session && session?.token) {
         setToken(session?.token)
      }
   }, [post])

   useEffect(() => {
      // console.log(post)
      if (post) {
         setIsLoading(true)
         const dataToPost = post.array ? post.array : new FormData()
         if (!post.array) {
            for (let index in post) {
               if (post.hasOwnProperty(index)) {
                  let value = post[index]
                  dataToPost.append(index, value)
               }
            }
         }

         const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: token ? token : '',
         }
         // console.log(dataToPost)

         axios
            .post(link, dataToPost, { headers })
            .then((res) => {
               setResponse(res.data)
               setIsLoading(false)
               setPost()
               setError()
            })
            .catch((err) => {
               // console.log(err)
               if (err) {
                  if (err.response) {
                     setError({
                        code: err.response.status,
                        message: err.response.data,
                     })
                  } else {
                     setError({
                        code: err.code,
                        message:
                           err.message + '\r\n Please check your internet. ',
                     })
                  }
               }
               setResponse()
               setPost()
               setIsLoading(false)
            })
      }
      // axios.post
   }, [post])

   return [response, error, isLoading, setPost]
}

// axios
//          .get(link, { headers })
//          .then((res) => {
//             setData(res.data)
//             setIsLoading(false)
//          })
//          .catch((err) => {
//             err &&
//                err.response &&
//                setError({
//                   code: err.response.status,
//                   message: err.response.data,
//                })
//             setIsLoading(false)
//          })

export function objectToFormData(obj) {
   const formData = new FormData()

   for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
         formData.append(key, obj[key])
      }
   }

   return formData
}

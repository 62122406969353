import { Button, TextInput } from 'flowbite-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FaUser } from 'react-icons/fa'
import { HiLockClosed, HiUser } from 'react-icons/hi'
import { apiLink } from '../../utilis/ApiContext'
import { usePopUpContext, useSessionContext } from '../../utilis/Context'
import { usePost } from '../../utilis/CustomHooks'

export default function SignUp({ handler, close, back }) {
   const [response, error, isLoading, setPost] = usePost(
      apiLink + '/auth/authenticate',
   )
   const { openPopUp } = usePopUpContext()
   const { startSession } = useSessionContext()
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm()

   const checkInputs = () => {
      // console.log(10)
      return errors?.username || errors?.password
      // return true
   }
   const handleClick = (data) => {
      // console.log(data)
      setPost(data)
      // handler()
   }

   useEffect(() => {
      // console.log(response)
      // console.log(error)
      if (response?.user) {
         startSession(response?.user, response.token, response.expiry)
         openPopUp({
            title: 'Connexion effectuée avec Succès',
            content: `Bon retour @${response?.user?.username}, sur KwetuEvent ✨.`,
            default: {
               title: '👊🏾',
               handler: close,
            },
         })
      }
      if (error) {
         // console.log(error)
         openPopUp({
            title: 'Erreur de Connexion',
            content: error?.message,
            decline: {
               title: 'Réessayer',
            },
         })
      }
   }, [response, error])

   return (
      <div className="flex w-full flex-col items-center  justify-center gap-4 px-4 py-8">
         <FaUser className="text-24 font-medium text-gray-400 dark:text-slate-400 md:text-24" />
         <div className="flex w-full flex-col gap-2 sm:gap-3">
            <TextInput
               rightIcon={HiUser}
               {...register('username', { required: true })}
               color={errors.username && 'failure'}
               id="username"
               type="text"
               placeholder="Nom d'utilisateur ou email"
               // value={email}
               // onChange={(event) => setEmail(event.target.value)}
               required
            />
            <TextInput
               {...register('password', { required: true })}
               color={errors.password && 'failure'}
               rightIcon={HiLockClosed}
               id="password"
               type="password"
               placeholder="Mot de passe"
               required
            />
         </div>

         <div className="flex w-full flex-col items-center justify-center gap-2">
            <Button
               disabled={errors.username || errors.password}
               isProcessing={isLoading}
               onClick={handleSubmit((e) => !checkInputs() && handleClick(e))}
               color="none"
               className="w-full bg-primary text-slate-200 hover:bg-dark"
            >
               Se Connecter
            </Button>
            {/* <span className="font-semibold text-dark dark:text-darktext">
                     ou
                  </span> */}
            <Button
               onClick={() => handler('enregistrement')}
               color="none"
               className="w-full border-none bg-gray-200 text-primary hover:bg-gray-700 hover:text-slate-200 dark:bg-gray-700 dark:text-darktext dark:hover:bg-dark"
            >
               Créer un compte
            </Button>
            <div className="flex w-full justify-center">
               <span
                  onClick={() => handler("vérification d'identité")}
                  className="cursor-pointer text-sm text-gray-600 hover:underline dark:text-slate-200"
               >
                  Mot de passe oublié ?{' '}
                  <span className="font-semibold text-primary">
                     Récupérer ici.
                  </span>
               </span>
            </div>
         </div>
      </div>
   )
}

import { Button, TextInput } from 'flowbite-react'
import { HiMail } from 'react-icons/hi'
// import {  } from 'react-icons/bi'
import { useEffect, useState } from 'react'
import { apiLink } from '../../utilis/ApiContext'
import { usePost } from '../../utilis/CustomHooks'
// import { useForm } from 'react-hook-form'
import { FaPassport } from 'react-icons/fa'
import {
   useFormModalContext,
   usePopUpContext,
   useSessionContext,
} from '../../utilis/Context'
import { countdown } from '../../utilis/functions'

export default function ResetPassword({ handler }) {
   const [code, setCode] = useState()
   const [showCode, setshowCode] = useState(false)
   const [min, setMin] = useState()
   const [email, setEmail] = useState()
   const [response, error, isLoadingVerify, setPost] = usePost(
      apiLink + '/auth/verify_code',
   )
   const [newCode, newCodeError, isLoadingNewCode, setPostNewCode] = usePost(
      apiLink + '/auth/verify_email',
   )
   const { startSession } = useSessionContext()
   const { openPopUp, closePopUp } = usePopUpContext()
   const { changeForm } = useFormModalContext()

   const handleVerify = () => {
      // console.log('post')
      code &&
         setPost({
            email: email,
            code: code,
         })
   }
   const handleNewCode = () => {
      // console.log('post')
      setPostNewCode({
         email: email,
         // code: code,
      })
   }
   // useEffect(() => {
   //    !min && countdown(5, setMin)
   // }, [])

   useEffect(() => {
      if (response?.user) {
         // console.log(response)
         startSession(response.user, response.token)
         openPopUp({
            title: 'Verification Email faite avec Succès',
            content: `Vous pouvez changer votre mot de passe dans le menu profile, \n\
            Bon retour sur KwetuEvent 😊`,
            default: {
               title: 'Ok',
               handler: () => {
                  closePopUp()
                  changeForm('mon compte')
               },
            },
         })
      }
      if (error) {
         openPopUp({
            title: 'Erreur de Verification Email',
            content: error.message,
            decline: {
               title: 'Réessayer',
            },
         })
      }
   }, [response, error])

   useEffect(() => {
      if (newCode === 'success') {
         // console.log(response)
         // refresh()
         // startSession(response.user, response.token).then(() => refresh())
         openPopUp({
            title: 'Nouveau Code OPT envoyé avec succès',
            content:
               'Nous avons envoyé une nouveau code ce verification à votre mail',
            default: {
               title: 'Ok',
               handler: () => {
                  setshowCode(true)
                  countdown(5, setMin)
               },
            },
         })
      }
      if (newCodeError) {
         openPopUp({
            title: 'Erreur de Verification Email',
            content: newCodeError.message,
            decline: {
               title: 'Réessayer',
            },
         })
      }
   }, [newCode, newCodeError])
   return (
      <div className="flex w-full flex-col items-center justify-center gap-4 px-4 pb-4">
         <HiMail className="mt-2 text-16 dark:text-gray-400 md:text-24" />
         {showCode ? (
            <div className="flex w-full flex-col gap-2 sm:gap-3">
               <label className="text-dark-400 dark:text-slate-200">
                  Veuillez entrer le code OTP que nous avons envoyé à votre
                  adresse mail: <span className="font-semibold">{email}</span>
               </label>
               <TextInput
                  rightIcon={FaPassport}
                  id="code"
                  type="number"
                  placeholder="Entrer le Code OTP"
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
                  required
               />
            </div>
         ) : (
            <div className="flex w-full flex-col gap-2 sm:gap-3">
               <label className="flex flex-col text-dark-400 dark:text-slate-200">
                  <span className="dark:text-gray-400">
                     {' '}
                     Nous allons passez à la vérification de votre identité,
                  </span>
                  <span className="font-semibold">
                     {' '}
                     Veuillez entrer votre email.
                  </span>
               </label>
               <TextInput
                  rightIcon={HiMail}
                  id="email"
                  type="email"
                  placeholder="name@domain.com"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  required
               />
            </div>
         )}
         <div className="flex w-full flex-col items-center justify-center gap-2">
            {showCode ? (
               <>
                  <Button
                     onClick={() => handleVerify()}
                     isProcessing={isLoadingVerify}
                     className="w-full bg-primary hover:bg-dark"
                  >
                     Vérifier l'OTP
                  </Button>
                  <Button
                     disabled={min}
                     color="gray"
                     onClick={() => handleNewCode()}
                     isProcessing={isLoadingNewCode}
                     className="flex w-full flex-row items-center gap-4 border text-primary dark:border-secondary dark:text-darktext"
                  >
                     <span>
                        Demander un nouveau Code dans{' '}
                        {min ? '(' + min + ')' : ''}
                     </span>
                  </Button>
               </>
            ) : (
               <Button
                  isProcessing={isLoadingNewCode}
                  onClick={() => handleNewCode()}
                  className="w-full bg-primary hover:bg-dark"
               >
                  Vérifier
               </Button>
            )}
            <div className="flex w-full justify-center">
               <span
                  onClick={() => handler('connexion')}
                  className="cursor-pointer text-sm text-gray-600 hover:underline dark:text-slate-200"
               >
                  Retourner à la page de{' '}
                  <span className="font-semibold text-primary">Connexion</span>
               </span>
            </div>
         </div>
      </div>
   )
}

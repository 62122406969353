import { Button } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaShoppingCart } from 'react-icons/fa'
import { HiMinus, HiOutlineTicket, HiPlus } from 'react-icons/hi'
import {
   getLoggedUser,
   useCartContext,
   useFormModalContext,
   usePopUpContext,
   useSessionContext,
} from '../../utilis/Context'
import CustomPopUp from '../CustomPopUp/CustomPopUp'
import { Email, Input } from '../Form'

export default function Booking({ event, show, onClose }) {
   // const event = null
   return (
      <div className="flex  w-full flex-col p-4">
         <span className="w-full px-2 text-center font-semibold sm-max:text-3">
            Sectionnez une Catégorie pour commencer.
         </span>
         <div className="flex flex-col justify-center gap-2 p-2">
            {event ? (
               event.ticket_categories.map((ticket) => (
                  <TicketCategory
                     // key={event.id + id}
                     event={event}
                     ticket={ticket}
                     ticketId={ticket.id}
                     type={ticket.type}
                     price={ticket.price}
                     key={ticket.type + ticket.price}
                     currency={ticket.currency}
                     spots={ticket.spots}
                     available={ticket.available}
                  />
               ))
            ) : (
               <TicketCategory free={true} />
            )}
         </div>
      </div>
   )
}

function TicketCategory({
   ticket,
   event,
   type,
   price,
   currency,
   spots,
   available,
}) {
   const [ticketPopUp, setTicketPopUp] = useState(false)
   const [showNameHolder, setShowNameHolder] = useState(false)
   const [ticketHolder, setTicketHolder] = useState(false)
   const [tickets, setTickets] = useState(1)
   const { changeForm } = useFormModalContext()
   const { loggedUser } = useSessionContext()
   const [bookedTickets, setBookedTickets] = useState([
      {
         spot: spots - available + 1,
         index: 1,
         name: loggedUser ? loggedUser?.first_name : '',
         // setName : (n) => {return this.name = n}
      },
   ])
   // const []
   const openPop = () => {
      setTicketPopUp(true)
   }
   const openTicketHolder = () => {
      setShowNameHolder(true)
      setTicketHolder(true)
   }
   const closePop = () => {
      setTicketPopUp(false)
   }
   const closeTicketHolder = () => {
      setTicketHolder(false)
   }

   const removeSpots = () => {
      tickets > 1 && setTickets(Number(tickets) - 1)
   }
   const addSpots = () => {
      tickets < available && setTickets(Number(tickets) + 1)
   }
   const handleChange = (e) => {
      Number(e.target.value) > 0 && Number(e.target.value) <= available
         ? setTickets(e.target.value)
         : setTickets(tickets)
   }

   const addTicket = (sum) => {
      // console.log(sum)
      for (let index = 0; index < sum; index++) {
         const lastTiket = bookedTickets[bookedTickets.length - 1]
         // console.log(lastTiket)
         setBookedTickets([
            ...bookedTickets,
            {
               spot: lastTiket.spot + 1,
               index: bookedTickets.length + 1,
               name: '',
               // setName : (n) => {return this.name = n}
            },
         ])
      }
   }
   const removeTicket = (sum) => {
      const holder = bookedTickets
      for (let index = 0; index < sum; index++) {
         holder.pop()
      }
      setBookedTickets(holder)
   }

   useEffect(() => {
      tickets <= 1 && setTickets(1)
      // console.log(1)
      if (bookedTickets.length < tickets) {
         addTicket(tickets)
      } else {
         if (bookedTickets.length > tickets && tickets > 0) {
            removeTicket(bookedTickets.length - tickets)
         }
      }
      // console.log(bookedTickets.length <= tickets)
   })

   return (
      <>
         <div
            onClick={() => openPop()}
            className="flex w-full cursor-pointer flex-row items-center justify-between gap-2 rounded-xl bg-bg p-2 px-4 dark:bg-dark"
         >
            <div className="flex flex-row items-center gap-4">
               <HiOutlineTicket className=" -rotate-45 text-8 text-blue-500" />
               <div className="flex flex-col">
                  <span className="test-slate-200 font-light sm-max:text-3">
                     {type}
                  </span>
                  <span className="font-bold text-gray-700 dark:text-slate-200 sm:text-5">
                     {price + ' ' + currency}
                  </span>
               </div>
            </div>
            <span className="material-icons-round justify-end text-gray-700 dark:text-slate-400">
               info
            </span>
         </div>
         <CustomPopUp
            title={
               showNameHolder
                  ? 'ticket Name Holder'
                  : 'Billet ' + type + ' ' + price + ' ' + currency
            }
            state={ticketPopUp}
            close={showNameHolder ? () => setShowNameHolder(false) : closePop}
         >
            {!showNameHolder ? (
               <div className="flex w-full flex-col gap-2 p-2">
                  {/* <div className="mb-2 flex flex-row items-center justify-around px-2 pb-2">
                     <HiOutlineTicket className=" -rotate-45 material-icons-round dark:opacity- rounded-full bg-opacity-60 bg-gradient-to-r from-indigo-400 to-cyan-700 p-2 text-10 text-slate-200" />
                     <span className="font-semibold text-dark-400 dark:text-darktext">
                        Tickets Disponible :
                     </span>
                     <span className="font-semibold text-dark-400 dark:text-darktext">
                        {available > 0 ? 'DISPONIBLE' : 'SOLD OUT'}
                     </span>
                  </div> */}
                  <div className="flex flex-row items-center justify-around gap-2 p-2">
                     <HiOutlineTicket className=" dark:opacity-  -rotate-45 rounded-full bg-opacity-60 bg-gradient-to-r from-cyan-600 to-blue-400 p-2 text-10 text-slate-200" />
                     <span className="font-semibold text-dark-400 dark:text-darktext">
                        Nombre des Billets :
                     </span>
                     <div className="flex flex-row items-center gap-2">
                        <HiMinus
                           onClick={() => removeSpots()}
                           className="material-icons-round cursor-pointer rounded-md bg-gradient-to-r from-pink-600 to-red-400 p-1 text-darktext dark:text-darktext"
                        />
                        <input
                           type="number"
                           onChange={(e) => handleChange(e)}
                           value={tickets}
                           className="max-w-8 border-none bg-inherit p-1 text-center font-semibold text-dark-400 dark:text-darktext"
                        />
                        <HiPlus
                           disabled={available > 0 ? false : true}
                           onClick={() => addSpots()}
                           className="material-icons-round cursor-pointer rounded-md bg-gradient-to-r from-green-600 to-emerald-400 p-1 text-darktext dark:text-darktext "
                        />
                     </div>
                  </div>
                  <Button
                     className="bg-gradient-to-r from-indigo-600 to-blue-700 text-slate-200"
                     color="blue"
                     onClick={() =>
                        loggedUser
                           ? openTicketHolder()
                           : changeForm('connexion')
                     }
                  >
                     <span className="font-bold text-white  dark:text-slate-200">
                        {' '}
                        Acheter
                     </span>
                  </Button>
               </div>
            ) : (
               <TicketHolderInfo
                  ticket={ticket}
                  event={event}
                  state={ticketHolder}
                  tickets={bookedTickets}
                  close={closeTicketHolder}
                  closeParent={closePop}
               />
            )}
         </CustomPopUp>
      </>
   )
}

function TicketHolderInfo({
   ticket,
   event,
   tickets,
   state,
   close,
   closeParent,
}) {
   const { loggedUser } = useSessionContext()
   const [email, setEmail] = useState(
      getLoggedUser() ? getLoggedUser().user.email : '',
   )
   const [checked, setChecked] = useState(true)
   const { openPopUp } = usePopUpContext()
   const { addToCart, cart } = useCartContext()
   const {
      register,
      handleSubmit,
      // formState: { errors },
   } = useForm()

   const handleAddToCart = (addToCart, cart) => {
      if (cart && cart.length > 0) {
         addToCart({
            id: event.id + 'ttk' + ticket.id + Math.floor(Math.random() * 100),
            event: event,
            ticket: ticket,
            tickets: tickets,
            email: email,
         })
         closeParent()
         close()
      } else {
         openPopUp({
            title: 'Confirmation',
            content:
               "Vos billets seront ajoutés au Panier, pour vous permettre d'acheter les billets des multiples Événements en même temps.",
            decline: {
               title: 'Annuler',
               // handler: closeParent,
            },
            accept: {
               title: 'Continuer',
               handler: () => {
                  closeParent()
                  close()
                  addToCart({
                     id:
                        event.id +
                        'ttk' +
                        ticket.id +
                        Math.floor(Math.random() * 100),
                     event: event,
                     ticket: ticket,
                     tickets: tickets,
                     email: email,
                  })
               },
            },
         })
      }
   }

   useEffect(() => {
      loggedUser && setEmail(loggedUser?.email)
   }, [loggedUser])
   return (
      // <CustomPopUp title={'Ticket Holder Info'} state={state} close={close}>
      <form
         onSubmit={handleSubmit((e) => e && handleAddToCart(addToCart, cart))}
         className="flex max-h-120 flex-col items-center  gap-2 overflow-y-scroll p-2"
      >
         <span className="text-center  text-dark-400 dark:text-darktext md:text-4">
            Veuillez saisir le nom complet pour chaque Billet
         </span>
         <>
            {tickets.map((ticket) => (
               <TicketNameHolder
                  register={register}
                  key={'name_h' + ticket?.spot}
                  ticket={ticket}
               />
            ))}
         </>
         <span className="mt-2 border-t px-2 pt-2 text-center text-3 text-dark-400 dark:border-dark dark:text-darktext md:text-4">
            Veuillez saisir l'adresse mail sur le quel vous voudrez recevoir les
            détails sur la confirmation des billets réservés.
         </span>
         <div className="flex w-full flex-col items-center justify-center gap-4 px-4 md:px-8">
            <Email
               register="email"
               value={email}
               sendValue={setEmail}
               required
               type={'mail'}
               name="email"
               placeholder={'votre adresse mail'}
               setChecked={setChecked}
               className=" border-none bg-darktext p-1 text-center text-dark dark:bg-dark dark:text-darktext"
            />

            <Button
               type="submit"
               color="blue"
               // onClick={}
               disabled={checked}
               className="flex w-full items-center gap-2 p-0"
            >
               {' '}
               <FaShoppingCart className="text-6" />
               Ajouter au Panier{' '}
            </Button>
         </div>
      </form>
      // {/* </CustomPopUp> */}
   )
}

function TicketNameHolder({ ticket, register }) {
   const setName = (n) => {
      ticket.name = n
   }
   return (
      <div className="flex w-full flex-col items-center justify-center gap-1 px-4 md:px-8">
         <span className="font-semibold text-dark-400 dark:text-darktext">
            Place N° {ticket.spot}
         </span>
         <Input
            register={'ticket-' + ticket?.spot}
            min={5}
            value={ticket?.name}
            sendValue={setName}
            required
            placeholder={'Saisir le nom du propriétaire'}
            name={'ticketHolder' + ticket.id}
            type={'text'}
            className="w-full border-none bg-darktext text-center text-dark dark:bg-dark dark:text-darktext"
         />
      </div>
   )
}

import { useEffect, useState } from 'react'
// import { styled } from 'styled-components'
import { Button, Modal, TextInput } from 'flowbite-react'
import { useForm } from 'react-hook-form'
import { CiBarcode } from 'react-icons/ci'
import { HiOutlineTicket, HiPhone } from 'react-icons/hi'
import { apiLink, assetsLink } from '../../utilis/ApiContext'
import {
   CartContext,
   getLoggedUser,
   PopUpContext,
   useEventModalContext,
   useFormModalContext,
} from '../../utilis/Context'
import { useFetch, usePost } from '../../utilis/CustomHooks'
import { MyLoader } from '../Loader'

export function getTotal(cartTotal) {
   if (cartTotal && cartTotal.length > 1) {
      let totale = ''
      cartTotal.forEach(({ total, currency }, index) => {
         if (index + 1 < cartTotal.length) {
            totale += total && currency && total + ' ' + currency + ' & '
         } else {
            totale += total && currency && total + ' ' + currency
         }
      })
      // console.log(totale)
      return totale
   } else {
      return (
         cartTotal &&
         cartTotal.length > 0 &&
         cartTotal[0].total + ' ' + cartTotal[0].currency
      )
   }
}

export default function Cart() {
   // const event = null
   const [payment, setPayment] = useState(false)
   const [title, setTitle] = useState({
      title: 'Votre Panier',
      icon: 'shopping_cart',
   })

   const openPayment = () => {
      setPayment(true)
   }
   const closePayment = () => {
      setPayment(false)
      setTitle({
         title: 'Votre Panier',
         icon: 'shopping_cart',
      })
   }

   return (
      <CartContext.Consumer>
         {({
            openModal,
            modalState,
            closeModal,
            cart,
            clearCart,
            cartTotal,
         }) => (
            <Modal size="3xl" show={modalState} onClose={closeModal} popup>
               <Modal.Body className="bottom-0 rounded-xl p-0 dark:bg-dark-400 sm-max:p-0">
                  <div className="flex h-full  w-full flex-col items-center rounded-xl bg-white dark:bg-dark-400">
                     <div className="flex w-full items-center justify-between rounded-t-xl border-b px-4 py-2 text-dark dark:border-none dark:bg-dark dark:text-darktext ">
                        <span
                           onClick={() => {
                              payment ? closePayment() : closeModal()
                           }}
                           className="material-icons-round cursor-pointer justify-end rounded p-1 text-dark hover:bg-darktext dark:text-darktext dark:hover:bg-dark-400"
                        >
                           keyboard_arrow_left
                        </span>

                        <span className="flex items-center gap-2 font-semibold">
                           <span className="material-icons-round">
                              {title.icon}
                           </span>
                           {title.title}
                        </span>

                        {!payment ? (
                           <span
                              onClick={() => clearCart()}
                              className="material-icons-round cursor-pointer justify-end rounded-full p-2 text-gray-600 hover:bg-darktext hover:text-red-700 dark:text-darktext dark:hover:bg-dark-400"
                           >
                              delete
                           </span>
                        ) : (
                           <span
                              // onClick={() => clearCart()}
                              className="material-icons-round cursor-pointer justify-end rounded-full p-2 text-dark hover:bg-darktext hover:text-red-700 dark:text-darktext dark:hover:bg-dark-400"
                           ></span>
                        )}
                     </div>
                     {!payment ? (
                        <div className="flex flex-col items-center gap-2 px-4 pb-4 pt-4 md:px-6 md:pb-6 ">
                           {cart.map(({ event, tickets, ticket, id }) => (
                              <BookedTicket
                                 id={id}
                                 event={event}
                                 spots={tickets}
                                 ticket={ticket}
                                 key={id}
                              />
                           ))}
                           <div className="flex w-full items-center justify-around border-b p-2 pb-4">
                              <span className="mt-2 px-2 text-center font-semibold text-dark-400 dark:text-darktext md:text-4">
                                 Montant Total :
                              </span>
                              <span className="mt-2 flex flex-col gap-1 px-2  text-center font-semibold text-dark-400 dark:text-darktext md:gap-2 md:text-4">
                                 {cartTotal && getTotal(cartTotal)}
                              </span>
                           </div>
                           <div className="flex w-full flex-col items-center justify-center gap-2">
                              <Button
                                 onClick={() => closeModal()}
                                 color="gray"
                                 className="w-full border-none text-primary dark:bg-dark dark:text-darktext"
                              >
                                 Continuer les Achats
                              </Button>
                              <Button
                                 onClick={() => openPayment()}
                                 disabled={cartTotal.length <= 0}
                                 color="blue"
                                 className="w-full bg-primary hover:bg-dark"
                              >
                                 Passer au Paiement
                              </Button>
                              <span className="mt-2 px-2 text-center text-dark-400 dark:text-darktext sm-max:text-3 md:text-4">
                                 Tout ce qui se trouve dans votre panier sera
                                 effacé lorsque vous quitterez cette page.
                              </span>
                           </div>
                        </div>
                     ) : (
                        <PopUpContext.Consumer>
                           {({ openPopUp }) => (
                              <Payment
                                 cart={cart}
                                 cartTotal={cartTotal}
                                 setTitle={setTitle}
                                 openPopUp={openPopUp}
                                 close={() => {
                                    clearCart()
                                    closeModal()
                                 }}
                              />
                           )}
                        </PopUpContext.Consumer>
                     )}
                  </div>
               </Modal.Body>
            </Modal>
         )}
      </CartContext.Consumer>
   )
}

function BookedTicket({ event, spots, id, ticket }) {
   return (
      <CartContext.Consumer>
         {({ deleteFromCart }) => (
            <div className="flex w-full cursor-pointer flex-row items-center justify-between gap-2 rounded-xl bg-bg p-2 dark:bg-dark">
               <HiOutlineTicket className=" -rotate-45 text-8 text-blue-500" />
               <div className="flex flex-col items-center text-dark dark:text-darktext-white">
                  <span className="font-semibold">{event.title}</span>
                  <span className="text-3 font-semibold text-dark-400 dark:text-darktext">
                     {ticket.type} Ticket {ticket.price + ' ' + ticket.currency}{' '}
                     x
                     <span className="text-primary">
                        {' '}
                        {'(' + spots.length + ' Tickets)'}
                     </span>
                  </span>
               </div>
               <span
                  onClick={() => deleteFromCart(id)}
                  className="material-icons-round justify-end rounded-full p-2 text-gray-600 hover:bg-darktext-white hover:text-red-700 dark:text-darktext dark:hover:bg-dark-400"
               >
                  delete
               </span>
            </div>
         )}
      </CartContext.Consumer>
   )
}

export function CustomPopUp({ children, state, close, title }) {
   return (
      state === true && (
         <div className="position fixed left-0 top-0 z-100 flex h-[100dvh] w-screen items-center justify-center bg-black bg-opacity-40 p-4">
            <div className="flex w-100 flex-col gap-2 rounded-xl bg-darktext-white pb-4 pl-2 pt-2 dark:bg-dark-400 md:w-120">
               <div className="flex h-10 w-full items-center justify-between pr-4">
                  <span
                     onClick={() => close()}
                     className="material-icons-round cursor-pointer justify-end rounded p-1 text-dark hover:bg-darktext dark:text-darktext"
                  >
                     keyboard_arrow_left
                  </span>
                  <span className="font-semibold">{title}</span>
                  <span></span>
               </div>
               {children}
            </div>
         </div>
      )
   )
}

function Payment({ cart, cartTotal, setTitle, openPopUp, close }) {
   const [method, setMethod] = useState(null)
   // const [checkout, setCheckOut] = useState({})
   // const [reserve, setReserve] = useState({})
   const [methods, isLoadingMethods] = useFetch(
      apiLink + '/api/payments/methods',
   )
   const [response, error, isLoading, setPost] = usePost(
      apiLink + '/api/events/reserve_many',
   )

   const handleMethod = (methd) => {
      setMethod(methd)
   }

   const { closeModal } = useFormModalContext()
   const { changeEvent } = useEventModalContext()

   const handlePost = () => {
      const data = []
      const code =
         'RSV' +
         Math.floor(Math.random() * 10000) +
         new Date().getMilliseconds().toString()
      cart.forEach(({ event, ticket, tickets, email }) => {
         tickets.forEach(({ spot, name }) => {
            data.push({
               event_id: event.id,
               code: code,
               name_holder: name,
               ticket_category_id: ticket.id,
               user_id: getLoggedUser().user.id,
               spot: spot,
            })
         })
      })

      setPost({ array: data })
   }
   const handleReserve = () => {
      // setReserve(true)
      openPopUp({
         title: 'Reservation',
         content:
            "Vous êtes sur le point d'effectuer une réservation des éléments de votre panier, le montant total est de " +
            getTotal(cartTotal),
         decline: {
            title: 'Annuler',
            // handler: closeParent,
         },
         accept: {
            title: 'Réserver',
            handler: () => {
               handlePost()
            },
         },
      })
   }

   useEffect(() => {
      if (response) {
         openPopUp({
            title: '✅ Réservation',
            content: 'Votre réservation a été effectuée avec succès',
            default: {
               title: '👊🏾',
               handler: () => {
                  close()
                  closeModal()
                  changeEvent()
               },
            },
         })
      }
      if (error) {
         // console.log(error)
         openPopUp({
            title: "Erreur d'authentification",
            content: error.message,
            decline: {
               title: 'Essayer à nouveau',
            },
         })
      }
   }, [response, error])

   useEffect(() => {
      return setTitle({
         title: 'Mode de Paiement',
         icon: 'payment',
      })
   }, [])
   return method ? (
      <Checkout
         method={method}
         setTitle={setTitle}
         openPopUp={openPopUp}
         close={close}
         cart={cart}
         cartTotal={cartTotal}
      />
   ) : (
      <div className="flex flex-col items-center gap-2 px-4 pb-4 md:px-6 md:pb-6 ">
         <span className="mt-2 px-2 text-center  text-dark-400 dark:text-darktext md:text-4">
            Choisissez ci-dessus le mode de paiement que vous souhaitez utiliser
         </span>
         <div className="justify-left flex w-full flex-wrap items-center gap-4 border-b p-2 pb-4">
            {isLoadingMethods && <MyLoader />}
            {methods &&
               methods.length > 0 &&
               methods.map(({ id, name, number, cover }) => (
                  <Method
                     onClick={handleMethod}
                     icon={assetsLink + 'methods/' + cover}
                     name={name}
                     number={number}
                     id={id}
                     key={id + name + cover}
                  />
               ))}
            {/* <Method
               onClick={handleMethod}
               icon={orange}
               name={'Orange Money'}
            />
            <Method onClick={handleMethod} icon={mpesa} name={'M-Pesa'} /> */}
            {/* <Method
               onClick={handleMethod}
               icon={mastercard}
               name={'mastercard'}
            />
            <Method onClick={handleMethod} icon={visa} name={'visa'} /> */}
         </div>
         {isLoading && (
            <div className="fixed left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-black bg-opacity-40">
               <MyLoader />
            </div>
         )}
         <div className="flex w-full flex-col items-center justify-center gap-2 p-2 md:p-4">
            <span className="text-center font-semibold text-dark-400 dark:text-darktext md:text-4">
               ou
            </span>
            <Button
               onClick={() => handleReserve()}
               color="gray"
               className="w-full border border-primary text-primary dark:border-none dark:bg-dark dark:text-darktext"
            >
               Réservez maintenant et payez plus tard
            </Button>
         </div>
      </div>
   )
}

function Method({ id, name, icon, onClick, number }) {
   return (
      <div
         onClick={() =>
            onClick({
               id: id,
               name: name,
               icon: icon,
               number: number,
            })
         }
         style={{
            backgroundImage: 'url(' + icon + ')',
         }}
         className=" flex h-24 w-24 cursor-pointer items-center justify-center rounded-xl bg-darktext bg-cover bg-center hover:bg-dark-400 hover:shadow-xs md:h-32 md:w-32"
      >
         {/* <img src={icon} alt={`${name}-icon`} /> */}
      </div>
   )
}

function Checkout({ method, setTitle, openPopUp, close, cart, cartTotal }) {
   const [response, error, isLoading, setPost] = usePost(
      apiLink + '/api/events/pay_many',
   )
   const [diffCurrency, setDiffCurrency] = useState(false)
   const { closeModal } = useFormModalContext()
   const { changeEvent } = useEventModalContext()

   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm()

   useEffect(() => {
      return setTitle({
         title: 'Check Out',
         icon: 'payment',
      })
   }, [])

   const checkInputs = () => {
      // console.log(10)
      return errors.telephone || errors.code
      // return true
   }
   const checkDevises = (cart) => {
      const currency = cart[0]?.ticket?.currency
      cart.forEach(({ ticket }) => {
         if (currency !== ticket.currency) {
            setDiffCurrency(true)
         }
      })
   }

   const handlePost = (e) => {
      const data = []
      cart.forEach(({ event, ticket, tickets, email }) => {
         // const ref = e?.code1 ?
         tickets.forEach(({ spot, name }) => {
            data.push({
               payment_method_id: method.id,
               event_id: event.id,
               code: e?.code1
                  ? ticket?.currency === 'USD'
                     ? e.code
                     : e?.code1
                  : e.code,
               telephone: e.telephone,
               name_holder: name,
               ticket_category_id: ticket.id,
               user_id: getLoggedUser().user.id,
               spot: spot,
            })
         })
      })

      // console.log(data)
      setPost({ array: data })
   }

   useEffect(() => {
      cart && checkDevises(cart)
   }, [cart])
   useEffect(() => {
      // console.log(cart)

      if (response) {
         openPopUp({
            title: '✅ Votre paiement a été effectué avec succès !',
            content: `Merci d'avoir choisi KwetuEvent ! \n\
                     Vous recevrez sous peu un email de confirmation avec votre billet électronique.`,
            default: {
               title: '👊🏾',
               handler: () => {
                  close()
                  closeModal()
                  changeEvent()
               },
            },
         })
      }
      if (error) {
         // console.log(error)
         openPopUp({
            title: "⚠️ Erreur d'Operation",
            content:
               "Oops! Une erreur s'ait produite, veuillez verifier votre connexion internet puis réessayer",
            decline: {
               title: 'Réessayer',
            },
         })
      }
   }, [response, error])

   return (
      <div className="flex flex-col items-center gap-2 px-4 pb-4 md:px-6 md:pb-6 ">
         <div className="flex flex-row gap-2 p-2">
            <div
               style={{
                  backgroundImage: 'url(' + method.icon + ')',
               }}
               className="h-10 w-10 rounded bg-cover bg-center"
            ></div>
            <span className="mt-2 px-2 text-center text-dark-400 dark:text-darktext md:text-4">
               Paiement avec {method.name}
            </span>
         </div>
         <div className="justify-left flex w-full flex-col flex-wrap items-center gap-4 p-2 pb-4 md:w-8/12">
            <span className="text-center font-semibold text-dark-400 dark:text-gray-400 sm-max:text-3">
               Après avoir effectué un dépôt sur le numéro {method.name} suivant
               :{' '}
               <span className="font-bold dark:text-gray-200">
                  {method.number}
               </span>
               <br />
               Veuillez remplir le formulaire c-dessous avec{' '}
               <span className="font-bold dark:text-gray-200">
                  le Code de Référence de la Transaction{' '}
               </span>{' '}
               et le numéro de téléphone avant de Confirmer
            </span>
            <span className="text-center font-semibold text-dark-400 dark:text-gray-400">
               Total à payer :{' '}
               <span className="font-bold dark:text-gray-200">
                  {getTotal(cartTotal)}
               </span>
            </span>
            <span className="text-center font-semibold text-dark-400 dark:text-gray-400">
               Numero {method.name} :
               <span className="font-bold dark:text-gray-200">
                  {' ' + method.number}
               </span>
            </span>
            <TextInput
               rightIcon={HiPhone}
               {...register('telephone', { required: true })}
               color={errors.telephone && 'failure'}
               id="telephone"
               type="text"
               placeholder="Entrez votre Numero"
               className="w-full"
               // value={email}
               // onChange={(event) => setEmail(event.target.value)}
               required
            />
            <TextInput
               rightIcon={CiBarcode}
               {...register('code', { required: true })}
               color={errors.code && 'failure'}
               id="code"
               type="text"
               placeholder={
                  diffCurrency
                     ? 'USD : Entrez le Code de la Transaction'
                     : 'Entrez le Code de la Transaction'
               }
               className="w-full"
               // value={email}
               // onChange={(event) => setEmail(event.target.value)}
               required
            />
            {diffCurrency && (
               <TextInput
                  rightIcon={CiBarcode}
                  {...register('code1', { required: true })}
                  color={errors.code && 'failure'}
                  id="code"
                  type="text"
                  placeholder="CDF : Entrez le Code de la Transaction"
                  className="w-full"
                  // value={email}
                  // onChange={(event) => setEmail(event.target.value)}
                  required
               />
            )}
         </div>
         <div className="flex w-full  flex-col items-center justify-center gap-2 p-2 md:w-8/12 md:p-4">
            <Button
               // isProcessing={}
               disabled={errors.telephone || errors.code}
               isProcessing={isLoading}
               onClick={handleSubmit((e) => !checkInputs() && handlePost(e))}
               // color="none"
               className="w-full bg-primary hover:bg-dark"
            >
               <span className="text-slate-200"> Confirmer le Paiement</span>
            </Button>
         </div>
      </div>
   )
}

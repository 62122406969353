import { createContext, useContext, useState } from 'react'
import { getLoggedUser } from '../Context'
import { useFetch } from '../CustomHooks'

//LINKS
// export const apiLink = 'http://localhost:4000'
export const appUrl = 'https://kwetuevent.easychik.com'
export const apiLink = 'https://kwetueventapi.easychik.com'
// export const apiLink = 'http://192.168.100.69:8888/kwetueventsapi'
// export const apiLink = 'http://localhost:8888/kwetueventsapi'
export const assetsLink = apiLink + '/public/images/'
export const eventAssetsLink = apiLink + '/public/images/events/'
export const ApiContext = createContext()

export function ApiProvider({ children }) {
   const [events, isLoadingEvents, eventError, refreshEvents] = useFetch(
      apiLink + '/api/get_events',
   )
   const [categories, isLoadingCategories, categoriesError, refreshCategories] =
      useFetch(apiLink + '/api/categories')
   const [hots, isLoadingHots, hotError, refreshHots] = useFetch(
      apiLink + '/api/events/hots',
   )
   const [next, isLoadingNext, nextError, refreshNext] = useFetch(
      apiLink + '/api/events/next',
   )
   const [banners, isLoadingBanners] = useFetch(apiLink + '/api/banners')
   const [reservations, setReservations] = useState([])
   const [payments, setPayments] = useState([])
   const [tickets, setTickets] = useState([])
   const [notifications, isLoadingNotif, notfError, refreshNotif] = useFetch(
      getLoggedUser() &&
         apiLink + '/api/users/notifications/' + getLoggedUser().user.id,
      true,
   )

   const setForceGet = () => {
      // setForceReservs(true)
      // setForceNotif(true)
      // setForceTicket(true)
      refreshAll()
   }
   const refreshAll = () => {
      refreshEvents()
      refreshCategories()
      refreshHots()
      refreshNext()
      refreshNotif()
   }
   const logOut = () => {
      // setNotifs()
      setTickets()
      setReservations()
      setPayments()
   }

   return (
      <ApiContext.Provider
         value={{
            refreshHots,
            refreshNext,
            events,
            eventError,
            hots,
            next,
            reservations,
            categories,
            setReservations,
            notifications,
            tickets,
            setTickets,
            setForceGet,
            logOut,
            payments,
            setPayments,
            isLoadingCategories,
            isLoadingNext,
            isLoadingHots,
            isLoadingNotif,
            isLoadingEvents,
            hotError,
            notfError,
            nextError,
            categoriesError,
            banners,
            isLoadingBanners,
            refreshAll,
         }}
      >
         {children}
      </ApiContext.Provider>
   )
}

export const useApiContext = () => {
   const context = useContext(ApiContext)

   if (typeof context === 'undefined') {
      throw new Error('useContext must be used inside SessionContextProvider')
   }

   return context
}

import { HiDotsHorizontal } from 'react-icons/hi'
import { useApiContext } from '../../utilis/ApiContext'
import { useCategoryModalContext } from '../../utilis/Context'
import { randomKey } from '../../utilis/functions'
import EventCard from '../EventCard'
import './index.css'

export default function Posts() {
   // const data = events
   const { events } = useApiContext()
   const { changeCategory } = useCategoryModalContext()
   return (
      <div className="flex h-full w-full flex-col gap-2 rounded-2xl bg-white px-2 pb-2 pt-4  dark:bg-dark-400 md:px-6 ">
         <div className="mb-1 flex flex-row justify-between">
            <span
               id="home"
               className="rounded-xl bg-white px-3 py-1 font-semibold dark:bg-dark dark:text-darktext "
            >
               Événements
            </span>
            <div
               onClick={() => changeCategory('tous les événements')}
               className="flex cursor-pointer items-center rounded-xl border bg-bg px-2 py-1 font-semibold hover:bg-white dark:border-none dark:bg-dark dark:text-darktext  md-max:text-3"
            >
               {' '}
               {/* <span className="material-icons-round">more_horiz</span>{' '} */}
               <HiDotsHorizontal />
            </div>
         </div>
         <div className="grid h-full w-full grid-cols-1 grid-rows-1 items-start justify-center gap-5 pb-3 md:grid-cols-2 lg:grid-cols-3">
            {events &&
               events.map((event) => (
                  <EventCard
                     event={event}
                     key={event?.id + 'events' + randomKey()}
                  />
               ))}
         </div>
         <div className="flex items-center justify-center p-2">
            <span className="loaderP"></span>
         </div>
      </div>
   )
}

// import { styled } from 'styled-components'
import { Datepicker, Modal } from 'flowbite-react'
import { HiChevronLeft } from 'react-icons/hi'
import { useApiContext } from '../../utilis/ApiContext'
import {
   CategoryModalContext,
   useExpoloreModalContext,
} from '../../utilis/Context'
import { randomKey } from '../../utilis/functions'

export default function Explore() {
   const { modalState, closeModal, changeDate } = useExpoloreModalContext()
   const { categories } = useApiContext()
   return (
      <Modal size="4xl" show={modalState} onClose={closeModal} popup>
         <div className="flex w-full items-center justify-center px-4 pb-2 pt-4">
            <div
               onClick={() => closeModal()}
               className="hoverbg-gray-200 flex cursor-pointer flex-row items-center gap-2 rounded-full px-2 py-1 font-bold text-gray-DEFAULT-600 dark:text-slate-200 dark:hover:bg-gray-600"
            >
               <HiChevronLeft className="text-5" />
            </div>
            <div className="flex w-full items-center justify-center">
               <span className="text-5 font-bold capitalize text-gray-600 dark:text-slate-200">
                  Découvrir les Événements
               </span>
            </div>
         </div>
         <Modal.Body className="rounded-b-xl dark:bg-dark-400 sm-max:p-0">
            <div className="mt-2 flex h-full min-h-120 w-full flex-col flex-wrap items-center rounded-xl bg-white p-4  dark:bg-dark-400">
               <div className="flex w-full flex-col items-center justify-center gap-1">
                  <span className="w-full font-semibold text-gray-600 dark:text-darktext-white">
                     Par Date{' '}
                  </span>
                  <Datepicker
                     onSelectedDateChanged={changeDate}
                     showTodayButton={false}
                     showClearButton={false}
                     minDate={new Date()}
                     language="fr-FR"
                     inline
                  />
                  <span className="mt-2 w-full font-semibold text-gray-600 dark:text-darktext-white">
                     Par Catégories{' '}
                  </span>
               </div>
               {categories ? (
                  <div className="mt-2 flex h-full w-full flex-row flex-wrap items-center gap-2 bg-white dark:bg-inherit  sm:rounded-xl">
                     {categories.map(({ id, name, total }) => (
                        <Category
                           key={'cat' + randomKey() + id}
                           name={name}
                           total={total}
                        />
                     ))}
                  </div>
               ) : (
                  <div className="mt-2 flex h-full w-full flex-row flex-wrap items-center gap-2 bg-white dark:bg-inherit  sm:rounded-xl">
                     <Category name={'Concert'} />
                     <Category name={'Sports'} />
                     <Category name={'Movies'} />
                     <Category name={'Comedy'} />
                     <Category name={'Party'} />
                     <Category name={'Conference'} />
                  </div>
               )}
            </div>
            {/* <Action /> */}
         </Modal.Body>
      </Modal>
   )
}

function Category({ name, icon, total }) {
   return (
      <CategoryModalContext.Consumer>
         {({ changeCategory }) => (
            <div
               onClick={() => changeCategory(name)}
               className="flex  cursor-pointer items-center gap-1 rounded-full bg-bg px-3 py-1 font-semibold hover:bg-primary hover:text-slate-200 dark:bg-gray-700"
            >
               <span className="material-icons-round text-5 text-gray-600 dark:text-slate-400">
                  verified
               </span>
               <span className="dark:text-slate-200">{name}</span>
               {total ? (
                  <span className="rounded-full bg-primary px-2 text-white">
                     {total}
                  </span>
               ) : (
                  <span className="rounded-full bg-pink-800 px-2 text-white">
                     {total}
                  </span>
               )}
            </div>
         )}
      </CategoryModalContext.Consumer>
   )
}
